import React, { useState, useCallback, useEffect } from 'react'
import PropTypes from 'prop-types'

import { Button, Typography, Box, Link as MuiLink } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { Link } from 'react-router-dom'

import { unsubscribe, resubscribe } from './service'
import UnsubscribeReason from './UnsubscribeReason'
import Title from 'components/Title'

const useStyles = makeStyles(() => ({
  mainContent: {
    textAlign: 'left',
  },
  actionContainer: {
    textAlign: 'center',
  },
  submitButton: {
    borderRadius: 40,
    width: '100%',
  },
}))

type UnsubscribeInvitesProps = {
  inviteToken: string
  messageTag: string
  subToken: string
  cleverRequired: boolean
}

const redirectInvite = (inviteToken: string, cleverRequired: boolean) =>
  window.location.assign(
    cleverRequired ? `/confirm/student/clever-signup/${inviteToken}` : `/confirm/student/signup/${inviteToken}`
  )

export const APPLICATION_DEADLINE_REMINDER_TAG = 'upcoming-application-deadline-reminder'

const DEFAULT_COPY_CONFIG = {
  title: 'We’re sad to see you go 💔',
  hasUnsubscribed: `We won't send you any more reminders about this invite, but your counselor may still send you emails via Scoir.`,
  hasResubscribed: `We will still send you reminders about this invite.`,
  promptUnsubscribed: `Please let us know why you're not interested in joining Scoir. We'd love your feedback.`,
  regret: 'I have regrets. I want to try Scoir (and keep receiving emails).',
  submit: 'Submit',
  doNotSubmitRegret: `Nevermind, I'd like to try Scoir`,
}
const HasUnsub = () => (
  <>
    You&apos;ve successfully unsubscribed from application reminders. You can still{' '}
    <Link to={`${import.meta.env.BASE_URL}/signin`}>log in</Link> to Scoir to apply to colleges and track deadlines.
  </>
)
const HasResub = () => (
  <>
    Great, you&apos;ll still receive application reminders.{' '}
    <Link to={`${import.meta.env.BASE_URL}/signin`}>Log in</Link> to Scoir to apply to colleges and track deadlines.
  </>
)
const HasError = ({ action }: { action: string }) => (
  <>
    An error occurred while {action}. Please contact Scoir{' '}
    <MuiLink
      href="https://www.scoir.com/support"
      target="_blank"
      rel="noopener noreferrer"
    >
      Support
    </MuiLink>{' '}
    for assistance.
  </>
)
const APP_DEADLINE_COPY_CONFIG = {
  ...DEFAULT_COPY_CONFIG,
  title: `Don't want these emails?`,
  hasUnsubscribed: <HasUnsub />,
  hasResubscribed: <HasResub />,
  promptUnsubscribed: `Please let us know why you're not interested in receiving application reminders. We'd love your feedback.`,
  regret: `Never mind, I'd like to keep receiving reminders`,
  submit: 'Submit',
  doNotSubmitRegret: `Never mind, I'd like to keep receiving reminders`,
}

const UnsubscribeInvites = ({ inviteToken, messageTag, subToken, cleverRequired }: UnsubscribeInvitesProps) => {
  const classes = useStyles()
  const [reason, setReason] = useState('')
  const [otherReason, setOtherReason] = useState('')
  const [hasUnsubscribeError, setHasUnsubscribeError] = useState(false)
  const [hasResubscribeError, setHasResubscribeError] = useState(false)
  const [hasUnsubscribed, setHasUnsubscribed] = useState(false)

  const [hasResubscribed, setHasResubscribed] = useState(false)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const isAppDeadline = messageTag === APPLICATION_DEADLINE_REMINDER_TAG

  const copyConfig = isAppDeadline ? APP_DEADLINE_COPY_CONFIG : DEFAULT_COPY_CONFIG

  useEffect(() => {
    document.title = 'Scoir - Unsubscribe'
  }, [])

  const submit = useCallback(() => {
    setHasUnsubscribeError(false)
    setIsSubmitting(true)
    unsubscribe(reason, otherReason, messageTag, subToken)
      .then(() => setHasUnsubscribed(true))
      .catch(() => setHasUnsubscribeError(true))
      .finally(() => setIsSubmitting(false))
  }, [reason, otherReason, messageTag, subToken])

  const undoSubmit = useCallback(() => {
    setHasResubscribeError(false)
    setIsSubmitting(true)
    resubscribe(messageTag, subToken)
      .then(() => {
        if (!isAppDeadline) {
          redirectInvite(inviteToken, cleverRequired)
        } else {
          setHasResubscribed(true)
        }
      })
      .catch(() => {
        setIsSubmitting(false)
        setHasResubscribeError(true)
      })
  }, [messageTag, isAppDeadline, subToken, inviteToken, cleverRequired])
  return (
    <Box>
      <Title
        title={copyConfig.title}
        subtitle={
          hasResubscribed
            ? copyConfig.hasResubscribed
            : hasUnsubscribeError || hasResubscribeError
            ? ''
            : hasUnsubscribed
            ? copyConfig.hasUnsubscribed
            : copyConfig.promptUnsubscribed
        }
      />
      <Box className={classes.mainContent}>
        {!hasResubscribed && (
          <>
            {!hasUnsubscribed && (
              <Box mb={2}>
                <UnsubscribeReason
                  isAppDeadline={isAppDeadline}
                  reason={reason}
                  setReason={setReason}
                  otherReason={otherReason}
                  setOtherReason={setOtherReason}
                />
              </Box>
            )}
            {(hasUnsubscribeError || hasResubscribeError) && (
              <Box mb={2}>
                <Typography
                  role="alert"
                  align="center"
                  color="error"
                >
                  {hasUnsubscribeError && <HasError action="unsubscribing" />}
                  {hasResubscribeError && <HasError action="resubscribing" />}
                </Typography>
              </Box>
            )}
            {!hasUnsubscribeError && !hasResubscribeError && (
              <Box className={classes.actionContainer}>
                <Box mb={2}>
                  {hasUnsubscribed ? (
                    <Button
                      color="primary"
                      size="large"
                      disabled={isSubmitting}
                      onClick={undoSubmit}
                    >
                      {copyConfig.regret}
                    </Button>
                  ) : (
                    <Button
                      className={classes.submitButton}
                      variant="contained"
                      color="primary"
                      disabled={!reason || isSubmitting}
                      onClick={submit}
                    >
                      {copyConfig.submit}
                    </Button>
                  )}
                </Box>
                {!hasUnsubscribed && (
                  <Box mb={2}>
                    <Button
                      color="primary"
                      size="large"
                      onClick={() => {
                        if (isAppDeadline) {
                          setHasResubscribed(true)
                        } else {
                          redirectInvite(inviteToken, cleverRequired)
                        }
                      }}
                    >
                      {copyConfig.doNotSubmitRegret}
                    </Button>
                  </Box>
                )}
              </Box>
            )}
          </>
        )}
      </Box>
    </Box>
  )
}

UnsubscribeInvites.propTypes = {
  inviteToken: PropTypes.string.isRequired,
  messageTag: PropTypes.string.isRequired,
  subToken: PropTypes.string.isRequired,
  cleverRequired: PropTypes.bool,
}

export default UnsubscribeInvites
