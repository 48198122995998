import qs from '@scoir/query-string'

import api from 'common/api'
import { useClickTrackingQueryParamsString } from 'components/QueryParamsProvider'
import { clearLocalStorage, setCookies, routeToApp } from 'common/login-service'
import { getTrackEventsSessionId } from 'common/get-track-events-session-id'
import { RegisterResponse } from './types'

type StudentInviteResponse = {
  Email: string
  FirstName: string
  HighSchool: {
    City: string
    State: string
    HighSchoolName: string
    CleverRequired: boolean
    ClassLinkEnabled: boolean
    ClassLinkRequired: boolean
    SISSyncEnabled: boolean
  }
  MatchedHighSchoolStudent: {
    FirstName: string
    LastName: string
  }
  Token: string
  IsMiddleSchoolStudent?: boolean
}

const decodeToken = (token: string) => {
  const base64Url = token.split('.')[1]
  const base64 = base64Url.replace('-', '+').replace('_', '/')
  return JSON.parse(window.atob(base64))
}

export const validateStudentInvite = async (
  token: string,
  setError: any,
  setInitialValues: any,
  setLoading: any,
  setTokenAuth: any,
  setUser: any
) => {
  api
    .post<unknown, StudentInviteResponse>(`/api/student/register/invite/validate/${token}`, {})
    .then((auth) => {
      const isMS = decodeToken(token).IsMiddleSchoolStudent
      auth.IsMiddleSchoolStudent = isMS || false
      setTokenAuth(auth)
      setUser({
        name: auth.MatchedHighSchoolStudent.FirstName,
        location: `${auth.HighSchool.City}, ${auth.HighSchool.State}`,
        highschool: auth.HighSchool.HighSchoolName,
        cleverRequired: auth.HighSchool.CleverRequired,
        cleverSyncEnabled: auth.HighSchool.SISSyncEnabled,
        classLinkEnabled: auth.HighSchool.ClassLinkEnabled,
        classLinkRequired: auth.HighSchool.ClassLinkRequired,
        email: auth.Email,
      })
      setInitialValues({
        password: {
          pass: '',
          passConfirm: '',
        },
        confirmTOS: false,
        recaptchaResponse: '',
      })
      setLoading(false)
    })
    .catch(() => {
      setError(true)
      setLoading(false)
    })
}

const registerStudentUser = async (
  values: any,
  tokenAuth: any,
  setConfirmError: any,
  setSubmitting: any,
  additionalParams: any
) => {
  const body: { [key: string]: any } = {
    ...tokenAuth,
    Category: 'High School Student',
    FirstName: tokenAuth.MatchedHighSchoolStudent.FirstName,
    HighSchoolStudentId: tokenAuth.MatchedHighSchoolStudent.HighSchoolStudentId,
    LastName: tokenAuth.MatchedHighSchoolStudent.LastName,
    GraduationYear: tokenAuth.MatchedHighSchoolStudent.GraduationYear,
    Password: {
      Pass: values.password.pass,
      PassConfirm: values.password.passConfirm,
    },
    RecaptchaResponse: values.recaptchaResponse,
  }
  const params = qs.stringify({
    ...additionalParams,
    sessionId: getTrackEventsSessionId(),
  })

  api
    .post<unknown, RegisterResponse>(`/api/student/confirm?${params}`, body)
    .then((loginResponse) => {
      if (loginResponse.access_token && loginResponse.UserTypes) {
        const userType = loginResponse.UserTypes[0]
        clearLocalStorage(userType)
        setCookies(userType, loginResponse.access_token)
        routeToApp(userType)
      } else {
        setConfirmError(true)
        setSubmitting(false)
      }
    })
    .catch(() => {
      setConfirmError(true)
      setSubmitting(false)
    })
}

export const useRegisterStudentUser = () => {
  const params = useClickTrackingQueryParamsString()
  return async (values: any, tokenAuth: any, setConfirmError: any, setSubmitting: any) => {
    return await registerStudentUser(values, tokenAuth, setConfirmError, setSubmitting, params)
  }
}
