import React, { useState } from 'react'
import { Box, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { FormikHelpers } from 'formik'

import api from 'common/api'
import Title from 'components/Title'
import EmailForm, { EmailFormValues } from 'components/EmailForm'
import ErrorBox from 'components/ErrorBox'
import { Link } from 'react-router-dom'
import ReturnToLogin from 'components/ReturnToLogin'

const useStyles = makeStyles((theme) => ({
  link: {
    color: theme.palette.primary.dark,
    textDecoration: 'underline',
  },
}))

const MiddleSchoolSignUp = () => {
  const classes = useStyles()
  const [email, setEmail] = useState('')
  const [error, setError] = useState(false)
  const [emailSent, setEmailSent] = useState(false)

  const resendMiddleSchoolInvite = (values: EmailFormValues, props: FormikHelpers<EmailFormValues>) => {
    props.setSubmitting(true)
    setEmail(values.email)
    api
      .post('/api/student/register/resendInvite', {
        email: values.email,
      })
      .then(() => {
        setEmailSent(true)
      })
      .catch(() => {
        setError(true)
        props.setSubmitting(false)
      })
  }

  if (emailSent) {
    return (
      <>
        <Title
          title="Check your email 📬"
          subtitle={`If an account matching ${email} is found, an email with further instructions will be sent.`}
        />
      </>
    )
  }
  return (
    <>
      {error && <ErrorBox error={<>{`Unable to resend invitation, please try again later.`}</>} />}
      <Title
        title="Hey there"
        subtitle="Right now, Scoir is only available to Middle School Students through their schools. If your school uses Scoir, check your email for a registration link from your counselor."
      />
      <Box
        pb={2}
        textAlign="left"
      >
        <Typography variant="body2">Can’t find your invite link? Enter your email below.</Typography>
      </Box>
      <EmailForm
        initialValue={{ email: '' }}
        handleSubmit={resendMiddleSchoolInvite}
        submitText="Resend Invite"
        fieldText="School Email"
        required={false}
      />
      <Typography>
        <Link
          to={`/signup${window.location.search}`}
          className={classes.link}
        >
          Not a Student?
        </Link>
      </Typography>
      <ReturnToLogin />
    </>
  )
}

export default MiddleSchoolSignUp
