import React from 'react'
import PropTypes from 'prop-types'

import { connect } from 'react-redux'

import { NotificationStack } from 'react-notification'

import { actions as snackbarActions } from 'store/snackbar'
import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  // TODO: TEMPORARY! This is a workaround for react-notifications
  snackbarContainer: {
    '& .notification-bar-active': {
      backgroundColor: `${theme.palette.error.main}!important`,
      top: '2rem!important',
      fontSize: '1.5rem!important',
      height: 40,
      width: '300!important',
    },
  },
}))
const Snackbar = ({ notifications, dismissNotification }) => {
  const classes = useStyles()
  return (
    <div className={classes.snackbarContainer}>
      <NotificationStack
        notifications={notifications}
        onDismiss={(notification) => dismissNotification(notification)}
      />
    </div>
  )
}

Snackbar.propTypes = {
  notifications: PropTypes.array.isRequired,
  dismissNotification: PropTypes.func.isRequired,
}

export default connect(
  (state) => ({
    notifications: state.snackbar.queue,
  }),
  {
    dismissNotification: snackbarActions.dismissSnackbarItem,
  }
)(Snackbar)
