import * as Yup from 'yup'

export const parentYupValidation = Yup.object().shape({
  firstName: Yup.string().required('This is Required.'),
  lastName: Yup.string().required('This is Required.'),
  password: Yup.object().shape({
    pass: Yup.string()
      .min(8, 'Your password must be at least 8 characters.')
      .required('Your password must be at least 8 characters.'),
    passConfirm: Yup.string()
      .oneOf([Yup.ref('pass')], 'Your passwords must match.')
      .required('Your passwords must match.'),
  }),
})
