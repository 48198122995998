import { makeStyles } from '@material-ui/core'
import LoginWithGoogleButton from './LoginWithGoogleButton'
import LoginWithCleverButton from './LoginWithCleverButton'
import LoginWithClassLinkButton from './LoginWithClassLinkButton'

const useStyles = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(2, 0),
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2),
  },
}))

type SSOButtonsProps = {
  actualSource?: string
  scid?: number
  disableGoogle?: boolean
  disableClever?: boolean
  disableClassLink?: boolean
}

const SSOButtons = ({ actualSource, scid, disableGoogle = false, disableClever = false, disableClassLink = false }: SSOButtonsProps) => {
  const classes = useStyles()
  return (
    <div className={classes.root}>
      { !disableGoogle && (<LoginWithGoogleButton
          source={actualSource}
          scid={scid}
        />)}
      { !disableClever && (<LoginWithCleverButton
          source={actualSource}
          scid={scid}
        />)}
      { !disableClassLink && (<LoginWithClassLinkButton
          source={actualSource}
          scid={scid}
        />)}
    </div>
  )
}

export default SSOButtons
