import React from 'react'

import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  footer: {
    backgroundColor: `rgba(23, 28, 44, 1)`,
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
    padding: theme.spacing(3),
    gap: theme.spacing(0.5),
    width: '100%',
    color: theme.palette.primary.contrastText,
    '& a': {
      color: theme.palette.primary.contrastText,
      textDecoration: 'underline',
      textDecorationColor: '#595c66',
      '&:hover, &:focus': {
        color: theme.palette.primary.contrastText,
        textDecorationColor: theme.palette.primary.contrastText,
      },
    },
  },
}))

const Footer = () => {
  const classes = useStyles()
  const currentYear = new Date().getFullYear()
  return (
    <div className={classes.footer}>
      <div>&copy; {currentYear} Scoir Inc. All Rights Reserved. </div>
      <a
        href="https://www.scoir.com/terms-of-service"
        target="_blank"
        rel="noopener noreferrer"
      >
        Terms of Service.
      </a>
      {' | '}
      <a
        href="https://www.scoir.com/accessibility"
        target="_blank"
        rel="noopener noreferrer"
      >
        Accessibility Commitment
      </a>
      {' | '}
      <a
        href="https://studentprivacypledge.org/"
        target="_blank"
        rel="noopener noreferrer"
      >
        Student Privacy Pledge
      </a>
      {' | '}
      <a
        href="https://technolutions.com/community/slate-preferred-partners"
        target="_blank"
        rel="noopener noreferrer"
      >
        Slate Preferred Partner
      </a>
    </div>
  )
}

export default Footer
