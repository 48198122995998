import { useContext } from 'react'

import context from './context'

export const useCountryMapping = () => {
  const { countryMapping } = useContext(context)
  return countryMapping
}
export const useCountryMappingAbbr = () => {
  const { countryMappingAbbr } = useContext(context)
  return countryMappingAbbr
}
