import React from 'react'
import { Link } from 'react-router-dom'
import { Button, Typography, makeStyles } from '@material-ui/core'

import Title from 'components/Title'

const useStyles = makeStyles((theme) => ({
  linkButton: {
    marginBottom: theme.spacing(2),
    '&:hover': {
      color: theme.palette.common.white,
    },
  },
  link: {
    color: theme.palette.primary.dark,
    textDecoration: 'underline',
    margin: theme.spacing(2, 0),
  },
}))

const ValidationError = () => {
  const classes = useStyles()

  return (
    <>
      <Title
        title={`Oops... 🙈`}
        subtitle="This invite is not valid."
      />
      <Button
        variant="contained"
        color="primary"
        component={Link}
        to="/signin"
        className={classes.linkButton}
      >
        Go to Log In
      </Button>
      <Typography color="textSecondary">
        Need help?{' '}
        <a
          className={classes.link}
          title="Contact Support"
          href="https://scoir.helpdocs.io/"
        >
          Contact Support
        </a>
      </Typography>
    </>
  )
}

export default ValidationError
