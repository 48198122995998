import window from 'browser-lib/window'

const KEY = 'scoir-auth-query-params'

const get = () => {
  return window.localStorage.getItem(KEY) || ''
}

const set = (params = window.location.search) => {
  window.localStorage.setItem(KEY, params)
  return params
}

export const clear = () => {
  window.localStorage.removeItem(KEY)
}

const getParams = () => {
  const params = get() || set()
  return params.replace(/^\?/, '')
}

export default getParams
