import React from 'react'
import { Link } from 'react-router-dom'

import { Box, Typography, makeStyles } from '@material-ui/core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUserGraduate, faUsers } from '@fortawesome/pro-regular-svg-icons'

import Title from 'components/Title'
import ReturnToLogin from 'components/ReturnToLogin'

const useStyles = makeStyles((theme) => ({
  userBoxes: {
    display: 'flex',
    gap: theme.spacing(2),
    justifyContent: 'center',
  },
  userBox: {
    flex: 1,
    border: '1px solid rgba(0, 125, 170, 0.5)',
    borderRadius: theme.spacing(0.5),
    padding: theme.spacing(2, 1),
    color: theme.palette.text.primary,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: theme.spacing(1),
    '&:hover': {
      borderColor: theme.palette.primary.main,
      textDecoration: 'none',
    },
  },
  icon: {
    width: theme.spacing(3),
    height: theme.spacing(3),
  },
  link: {
    color: theme.palette.primary.dark,
    textDecoration: 'underline',
    margin: theme.spacing(2, 0),
  },
  otherUser: {
    margin: theme.spacing(2),
  },
}))

const SignUp = () => {
  const classes = useStyles()
  return (
    <>
      <Title
        title="Select account type"
        subtitle="Which best describes you?"
      />
      <Box className={classes.userBoxes}>
        <Link
          to={`/signup/student${window.location.search}`}
          className={classes.userBox}
        >
          <FontAwesomeIcon
            className={classes.icon}
            icon={faUserGraduate}
          />
          <Typography>Student</Typography>
        </Link>
        <Link
          to={`/signup/parent${window.location.search}`}
          className={classes.userBox}
        >
          <FontAwesomeIcon
            className={classes.icon}
            icon={faUsers}
          />
          <Typography>Parent/Guardian</Typography>
        </Link>
      </Box>
      <Typography
        color="textSecondary"
        className={classes.otherUser}
      >
        Are you a High School, CBO, or College?{' '}
        <a
          className={classes.link}
          href="https://www.scoir.com/choose-account-type"
          target="_blank"
          rel="noopener noreferrer"
        >
          Learn More
        </a>
      </Typography>
      <ReturnToLogin />
    </>
  )
}

export default SignUp
