import React from 'react'
import PropTypes from 'prop-types'

import { Box } from '@material-ui/core'

import CollegeCrest from '@scoir/college-crest'
import AvatarWithTitle, { Title } from '@scoir/avatar-with-title'

const CollegeAvatarWithTitle = ({
  scid,
  title,
  children,
  avatarProps,
  titleComponent: TitleComponent = Title,
  titleComponentProps,
  ...rest
}) => {
  return (
    <AvatarWithTitle
      m={0}
      avatar={
        <CollegeCrest
          {...avatarProps}
          aria-label={title}
          name={title}
          scid={scid}
        />
      }
      title={
        <Box ml={3}>
          <TitleComponent {...titleComponentProps}>{title}</TitleComponent>
        </Box>
      }
      {...rest}
    >
      {children}
    </AvatarWithTitle>
  )
}

CollegeAvatarWithTitle.propTypes = {
  scid: PropTypes.number,
  title: PropTypes.string,
  children: PropTypes.any,
  titleComponent: PropTypes.any,
  titleComponentProps: PropTypes.object,
  avatarProps: PropTypes.object,
  additionalBodyProps: PropTypes.object,
}

export default CollegeAvatarWithTitle
