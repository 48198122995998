import types from './types'

import { without } from 'lodash'

import produce from 'immer'

export { default as actions } from './actions'

const INITIAL_STATE = {
  queue: [],
}

const snackbar = (state = INITIAL_STATE, action = {}) => {
  switch (action.type) {
    case types.ADD_SNACKBAR_ITEM:
      return produce(state, (draftState) => {
        draftState.queue.push(action.payload)
      })
    case types.DISMISS_SNACKBAR_ITEM:
      return produce(state, (draftState) => {
        draftState.queue = without(state.queue, action.payload)
      })
    default:
      return state
  }
}
export default snackbar
