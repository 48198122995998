import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'

import api from 'common/api'

import context from './context'

import { reduce } from 'lodash'

export const getMappingAbbr = (mapping) => {
  return reduce(
    mapping,
    (result, value) => {
      result[value.Alpha2] = value.Alpha3
      return result
    },
    {}
  )
}

const CountryProvider = ({ children }) => {
  const [countryMapping, setCountryMapping] = useState()
  const [countryMappingAbbr, setCountryMappingAbbr] = useState({})
  useEffect(() => {
    if (!countryMapping) {
      api.get('/api/countries').then((res) => {
        setCountryMapping(res)
        const mappingAbbr = getMappingAbbr(res)
        setCountryMappingAbbr(mappingAbbr)
      })
    }
  }, [countryMapping])
  return <context.Provider value={{ countryMapping, countryMappingAbbr }}>{children}</context.Provider>
}
CountryProvider.propTypes = {
  children: PropTypes.node,
}

export default CountryProvider
