import React from 'react'

import LoginWithCleverButton, { VALID_REDIRECT_URIS } from 'components/SSOButtons/LoginWithCleverButton'
import CleverSyncError from '../components/CleverSyncError'
import Title from 'components/Title'

const CleverRequired = () => {
  const [needsFallbackMatch, setNeedsFallbackMatch] = React.useState(false)
  return (
    <>
      {needsFallbackMatch ? (
        <CleverSyncError />
      ) : (
        <>
          <Title title="Your school uses Clever to log in" />
          <LoginWithCleverButton
            onError={() => setNeedsFallbackMatch(true)}
            redirectUri={VALID_REDIRECT_URIS.cleverLink}
          />
        </>
      )}
    </>
  )
}

export default CleverRequired
