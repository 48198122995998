import React, { useState } from 'react'
import { Autocomplete } from '@material-ui/lab'
import { Box, TextField, Typography } from '@material-ui/core'

import api from 'common/api'

const OTHER_HS_TEXT = "I don't see my school"
export const OTHER_HS_ID = '5537cc06a9663b65a9ca4ea2'
export const otherSchoolSelection = {
  Name: OTHER_HS_TEXT,
  tokens: [OTHER_HS_TEXT, '', ''],
  value: OTHER_HS_ID,
}

export type HighSchoolOption = {
  HighSchoolId: string
  HighSchoolName: string
  isOther: boolean
  City: string
  State: string
  Active: boolean
  CleverRequired: boolean
  SISSyncEnabled: boolean
  SISSyncPerformed: boolean
  ClassLinkEnabled: boolean
  ClassLinkRequired: boolean
}

type FindDataResponse = {
  value: string
  tokens: string[]
  name: string
}

type HighSchoolAutocompleteProps = {
  onSelect: (school: HighSchoolOption | null) => void
  inputId: string
}

const HighSchoolAutocomplete = ({ onSelect, inputId }: HighSchoolAutocompleteProps) => {
  const [open, setOpen] = useState(false)
  const [options, setOptions] = useState<HighSchoolOption[]>([])

  const handleOpen = () => {
    setOpen(!open)
  }

  const onSearch = (e: any) => {
    const searchName = e.target.value
    if (!searchName) {
      return setOptions([])
    }
    return api.get<FindDataResponse[]>(`/api/high_schools/find/${searchName}`).then((highSchools) => {
      const options = [...highSchools, otherSchoolSelection]
      const results = options.map((hs) => ({
        HighSchoolId: hs.value,
        HighSchoolName: hs.tokens[0],
        isOther: hs.value === OTHER_HS_ID,
        City: hs.tokens[1],
        State: hs.tokens[2],
        Active: hs.tokens[3] === 'Active',
        CleverRequired: hs.tokens[4] === 'CleverRequired',
        SISSyncEnabled: hs.tokens[5] === 'SISSyncEnabled',
        SISSyncPerformed: hs.tokens[6] === 'SISSyncPerformed',
        ClassLinkRequired: hs.tokens[7] === 'ClassLinkRequired',
        ClassLinkEnabled: hs.tokens[8] === 'ClassLinkEnabled',
      }))
      setOptions(results)
      setOpen(true)
    })
  }

  const renderOptions = (option: HighSchoolOption) => {
    const hasLocation = option.City && option.State
    return (
      <Box>
        <Typography>{option.HighSchoolName}</Typography>
        {hasLocation && (
          <Typography
            variant="caption"
            color="textSecondary"
          >{`${option.City}, ${option.State}`}</Typography>
        )}
      </Box>
    )
  }

  const handleDefaultOption = (options: HighSchoolOption[]) => {
    return options
  }

  const handleChange = (e: any, val: string | HighSchoolOption | null) => {
    if (!val) {
      onSelect(null)
      return
    }
    const pickedSchool = { ...(val as HighSchoolOption) }
    if (pickedSchool.isOther) pickedSchool.HighSchoolName = 'Other'
    onSelect(pickedSchool)
  }

  return (
    <div className="high-school-search-comp">
      <Autocomplete
        freeSolo
        open={open}
        onClose={handleOpen}
        getOptionLabel={(option) => option.HighSchoolName}
        renderOption={renderOptions}
        options={options}
        onChange={handleChange}
        filterOptions={handleDefaultOption}
        renderInput={(params) => (
          <TextField
            {...params}
            onChange={onSearch}
            label="Search High Schools"
            variant="outlined"
            InputProps={{
              id: inputId,
              ...params.InputProps,
            }}
          />
        )}
      />
    </div>
  )
}

export default HighSchoolAutocomplete
