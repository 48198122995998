import types from './types'

import generateUniqueId from './uniq-id'

const DEFAULT_TIMEOUT = 6000

const addSnackbarItem = (message, timeout = DEFAULT_TIMEOUT) => {
  return (dispatch) =>
    dispatch({
      type: types.ADD_SNACKBAR_ITEM,
      payload: {
        key: generateUniqueId(),
        message,
        dismissAfter: timeout,
      },
    })
}
const dismissSnackbarItem = (notification) => {
  return (dispatch) =>
    dispatch({
      type: types.DISMISS_SNACKBAR_ITEM,
      payload: notification,
    })
}

const actions = {
  addSnackbarItem,
  dismissSnackbarItem,
}
export default actions
