import React from 'react'
import { connect } from 'react-redux'
import { noop } from 'lodash'

import qs from '@scoir/query-string'

import { actions as snackbarActions } from 'store/snackbar'
import api from 'common/api'
import { setCookies, routeToApp } from 'common/login-service'

import cleverIcon from './clever-icon.svg'
import StyledButton from '../styledButton'

export const VALID_REDIRECT_URIS = {
  cleverLogin: 'clever_login',
  cleverLink: 'oauth/clever/link',
}

export const MESSAGE_KEY = 'scoir.cleverSignUpResult'
export const MESSAGE_LISTENER_EXPIRATION = 300000

type LoginWithCleverButtonProps = {
  addSnack: (message: string) => void
  redirectUri?: string
  onError?: () => void
  scid?: number
  source?: string
}

const LoginWithCleverButton = ({ addSnack, redirectUri, onError, scid, source }: LoginWithCleverButtonProps) => {
  const [isRedirecting, setIsRedirecting] = React.useState(false)

  const messageEventListener = (e: any) => {
    const message = e.data
    if (message.key === MESSAGE_KEY) {
      if (message.success) {
        const userType = 'Student'
        setIsRedirecting(true)
        setCookies(userType, message.accessToken)
        const params = {
          scid: scid && scid > 0 ? scid.toString() : undefined,
          source: source,
        }
        const finalParams = qs.stringify(params)
        routeToApp(userType, finalParams)
      } else if (message.redirect) {
        if (message.cookie) {
          document.cookie = message.cookie
        }
        window.location.href = message.redirect
      } else {
        onError && onError()
      }
    }
  }

  React.useEffect(() => {
    if (redirectUri === VALID_REDIRECT_URIS.cleverLink) {
      window.addEventListener('message', messageEventListener)
      const expireMessageListener = setTimeout(() => {
        window.removeEventListener('message', messageEventListener)
      }, MESSAGE_LISTENER_EXPIRATION)
      return function onUnmount() {
        clearTimeout(expireMessageListener)
        window.removeEventListener('message', messageEventListener)
      }
    }
  })

  const oauthInit = () => {
    const params: { [key: string]: string } = {}
    if (scid) {
      params.scid = scid.toString()
    }
    if (source) {
      params.source = source
    }
    const queryParams = new URLSearchParams(params)
    const paramString = queryParams.toString()
    const query = `?redirect_uri=${window.location.protocol}//${window.location.host}/${redirectUri}${
      paramString && `&${paramString}`
    }`
    api
      .get<string>(`/api/oauth/init/clever${query}`)
      .then((uri) => {
        const decodedUrl = decodeURIComponent(uri.replace(/"/g, '')).replace(/\\u0026/g, '&')
        if (redirectUri === VALID_REDIRECT_URIS.cleverLink) {
          window.open(decodedUrl)
        } else {
          window.location.href = decodedUrl
        }
      })
      .catch(() => addSnack('An error occured while signing in with Clever.'))
  }

  return (
    <StyledButton
      onClick={oauthInit}
      icon={cleverIcon}
      disabled={isRedirecting}
      ssoTitle="Clever"
    />
  )
}

LoginWithCleverButton.defaultProps = {
  redirectUri: VALID_REDIRECT_URIS.cleverLogin,
  onError: noop,
}

export default connect(null, {
  addSnack: snackbarActions.addSnackbarItem,
})(LoginWithCleverButton)
