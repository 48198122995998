import React, { ReactNode } from 'react'

import { Theme, Typography, makeStyles } from '@material-ui/core'

const useStyles = makeStyles<Theme, { hasTitle: boolean; hasSubtitle: boolean }>((theme) => ({
  title: {
    textAlign: 'left',
    marginBottom: ({ hasSubtitle }) => (hasSubtitle ? theme.spacing(1) : theme.spacing(2)),
  },
  subtitle: {
    textAlign: 'left',
    margin: ({ hasTitle }) => (hasTitle ? theme.spacing(0, 0, 2, 0) : theme.spacing(2, 0)),
  },
}))

const Title = ({ title, subtitle }: { title?: ReactNode; subtitle?: ReactNode }) => {
  const classes = useStyles({ hasTitle: Boolean(title), hasSubtitle: Boolean(subtitle) })
  return (
    <>
      {title && (
        <Typography
          variant="h4"
          className={classes.title}
        >
          {title}
        </Typography>
      )}
      {subtitle && (
        <Typography
          variant="body2"
          className={classes.subtitle}
        >
          {subtitle}
        </Typography>
      )}
    </>
  )
}

export default Title
