import { UserType } from 'routes/register/byInvitation/helper/types.ts'

export const getSSOStatus = (user: UserType, userType: string) => {

  if (userType !== 'student') {
    return {
      cleverEnabled: false,
      classLinkEnabled: false,
      ssoEnabled: false,
      cleverRequired: false,
      classLinkRequired: false,
      ssoRequired: false,
    }
  }

  const cleverEnabled = !!user?.cleverSyncEnabled
  const classLinkEnabled = !!user?.classLinkEnabled
  const ssoEnabled = cleverEnabled || classLinkEnabled

  const cleverRequired = !!user?.cleverRequired
  const classLinkRequired = !!user?.classLinkRequired
  const ssoRequired = cleverRequired || classLinkRequired

  return {
    cleverEnabled,
    classLinkEnabled,
    ssoEnabled,
    cleverRequired,
    classLinkRequired,
    ssoRequired,
  }
}
