import React from 'react'

import LoginWithClassLinkButton from 'components/SSOButtons/LoginWithClassLinkButton'
import Title from 'components/Title'

const ClassLinkRequired = () => {
  return (
    <>
      <Title title="Your school uses ClassLink to log in" />
      <LoginWithClassLinkButton />
    </>
  )
}

export default ClassLinkRequired
