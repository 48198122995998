import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { Box, Button, Typography, makeStyles } from '@material-ui/core'

import HighSchoolAutocomplete, { HighSchoolOption, OTHER_HS_ID } from 'components/HighSchoolAutocomplete'

import { useEmailSignUp, useMiddleSchoolSignUp, useSelectHighSchool } from '../SignUpProvider/sign-up-context'

import Title from 'components/Title'
import TermsAndPrivacy from 'components/TermsAndPrivacy'
import ReturnToLogin from 'components/ReturnToLogin'
import OrLine from 'components/OrLine'
import SSOButtons from 'components/SSOButtons'
import api from 'common/api'

const useStyles = makeStyles((theme) => ({
  button: {
    marginTop: theme.spacing(2),
  },
  link: {
    color: theme.palette.primary.dark,
    textDecoration: 'underline',
  },
}))

const HIGHSCHOOL_TYPEAHEAD_ID = 'auth-signup-highschool-typeahead-id'
const OTHER_HIGH_SCHOOl = {
  HighSchoolId: OTHER_HS_ID,
  HighSchoolName: 'Other',
  isOther: true,
}

export const SelectHighSchool = () => {
  const classes = useStyles()
  const [selected, setSelected] = useState<HighSchoolOption | null>(null)
  const handleSetSelected = (highSchool: HighSchoolOption | null) => setSelected(highSchool)
  const selectHighSchool = useSelectHighSchool()
  const signUpWithEmail = useEmailSignUp()
  const middleSchoolSignUp = useMiddleSchoolSignUp()
  const [middleSchoolFeatureEnable, setMiddleSchoolFeatureEnabled] = useState(false)

  useEffect(() => {
    api.get<{ Name: string; Enabled: boolean }>('/api/features/MiddleSchoolRegistration').then((res) => {
      setMiddleSchoolFeatureEnabled(res?.Enabled ?? false)
    })
  }, [])

  return (
    <Box>
      <Title
        title="Create a student account"
        subtitle="Which high school do you attend?"
      />
      <label
        className="sr-only"
        htmlFor={HIGHSCHOOL_TYPEAHEAD_ID}
      >
        Select your high school. Search for a school and then use your arrow keys and press enter to select:
      </label>
      <HighSchoolAutocomplete
        inputId={HIGHSCHOOL_TYPEAHEAD_ID}
        onSelect={handleSetSelected}
      />
      <Button
        color="primary"
        variant="contained"
        fullWidth
        className={classes.button}
        disabled={selected === null}
        onClick={() => selectHighSchool(selected)}
      >
        Continue
      </Button>
      {middleSchoolFeatureEnable && (
        <Button
          color="primary"
          fullWidth
          className={classes.button}
          onClick={() => middleSchoolSignUp()}
        >
          I’m not in high school yet
        </Button>
      )}
      <Button
        color="primary"
        fullWidth
        className={classes.button}
        onClick={() => signUpWithEmail(OTHER_HIGH_SCHOOl)}
      >
        I graduated already
      </Button>

      <OrLine />

      <SSOButtons />
      <TermsAndPrivacy />
      <Typography>
        <Link
          to={`/signup${window.location.search}`}
          className={classes.link}
        >
          Not a Student?
        </Link>
      </Typography>
      <ReturnToLogin />
    </Box>
  )
}

export default SelectHighSchool
