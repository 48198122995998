import React from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'

import { get } from 'lodash'
import { Box, makeStyles } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  container: {
    backgroundColor: '#f5f5f5',
    margin: theme.spacing(2, 0),
    padding: theme.spacing(2),
    borderRadius: theme.spacing(0.5),
    border: '1px solid #e3e3e3',
    boxShadow: 'inset 0 1px 1px rgba(0, 0, 0, 0.05)',
  },
  pending: {
    color: '#745ca1',
    borderColor: '#745ca1',
    backgroundColor: 'rgba(116, 92, 161, 0.2)',
  },
  verifying: {
    color: theme.palette.primary.main,
    borderColor: theme.palette.primary.main,
    backgroundColor: 'rgba(21, 118, 154, 0.2)',
  },
  verified: {
    color: '#587f1b',
    borderColor: '#587f1b',
    backgroundColor: 'rgba(88, 127, 27, 0.2)',
  },
  error: {
    color: '#C23A16',
    borderColor: '#C23A16',
    backgroundColor: 'rgba(194, 58, 22, 0.2)',
  },
}))

const DomainStateDisplay = ({ state, domainName }) => {
  const classes = useStyles()
  const config = getStateDisplay(state)
  return (
    <Box className={cn(classes.container, classes[config.className])}>
      <strong className="domain-name">{domainName}</strong>&nbsp;
      <span className="domain-status-label">{config.label}</span>&nbsp;
    </Box>
  )
}
DomainStateDisplay.propTypes = {
  state: PropTypes.string.isRequired,
  domainName: PropTypes.string.isRequired,
}

export default DomainStateDisplay

export const stateDisplay = {
  initiated_state: {
    label: 'Pending School Action',
    description:
      'In order to send emails via Scoir, you must update your DNS configuration with the records provided below.',
    className: 'pending',
  },
  mailgun_registered_state: {
    label: 'Pending School Action',
    className: 'pending',
    description:
      'In order to send emails via Scoir, you must update your DNS configuration with the records provided below.',
  },
  verifying_state: {
    label: 'Verifying',
    className: 'verifying',
    description: `We're verifying your domain so you can send emails via Scoir after your domain is verified. Check back within 24-48 hours for the latest status.`,
  },
  verified_state: {
    label: 'Verified',
    className: 'verified',
    description: 'Your domain is verified! You can send emails via Scoir.',
  },
  verification_failed_state: {
    label: 'Verification Failed',
    className: 'error',
    description: (
      <span>
        Double check your DNS configuration to confirm it&apos;s updated with the records provided below. Reach out to{' '}
        {
          <a
            className="error-support"
            href="mailto:support@scoir.com?subject=Domain%20Verification%20Failed"
          >
            support@scoir.com
          </a>
        }{' '}
        with any questions.
      </span>
    ),
  },
  unknown_state: {
    label: 'Unknown',
    description: 'This domain is in an unknown state.',
    className: 'unknown',
  },
}
const getStateDisplay = (state) => get(stateDisplay, state, stateDisplay['unknown_state'])
export const getDescription = (state) =>
  get(stateDisplay[state], 'description', stateDisplay['unknown_state'].description)
