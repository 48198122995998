import React from 'react'
import { Formik, Form, Field, ErrorMessage, FormikHelpers } from 'formik'
import { Button, TextField, Typography, makeStyles } from '@material-ui/core'
import { Autocomplete } from '@material-ui/lab'
import ReCAPTCHA from 'react-google-recaptcha'
import { states } from '@scoir/states'

import TermsAndPrivacy from 'components/TermsAndPrivacy'

import CountryTypeahead from 'components/RegistrationForm/CountryTypeahead'
import { useCountryMapping } from 'components/RegistrationForm/CountryMappingProvider/hooks'
import provinces from 'components/RegistrationForm/provinces'
import { parentYupValidation } from './helper'

const useStyles = makeStyles((theme) => ({
  registrationForm: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2),
  },
  selectField: {
    textAlign: 'left',
  },
  centerAlign: {
    display: 'flex',
    justifyContent: 'center',
  },
}))

type State = {
  name: string
  value: string
  region: string
  tokens: string[]
}

export type ParentRegistrationFormValues = {
  firstName: string
  lastName: string
  password: {
    pass: string
    passConfirm: string
  }
  confirmTOS?: boolean
  recaptchaResponse?: string
  country?: string
  city?: string
  state?: string
}

type ParentRegistrationFormProps = {
  initialValue?: ParentRegistrationFormValues
  handleSubmit: (values: ParentRegistrationFormValues, props: FormikHelpers<ParentRegistrationFormValues>) => void
  confirmError?: boolean
}

const ParentRegistrationForm = ({ initialValue, handleSubmit, confirmError }: ParentRegistrationFormProps) => {
  const [isUSA, setIsUSA] = React.useState<boolean>(true)
  const [isCanada, setIsCanada] = React.useState<boolean>(false)
  const isCanadaOrUSA = isUSA || isCanada
  const countryMapping = useCountryMapping()
  const classes = useStyles()
  const recaptchaRef = React.createRef<ReCAPTCHA>()

  if (!initialValue) {
    return null
  }
  return (
    <Formik
      initialValues={initialValue}
      validateOnMount
      validationSchema={parentYupValidation}
      onSubmit={handleSubmit}
    >
      {({ values, errors, touched, isValid, isSubmitting, setFieldValue, setFieldTouched }) => (
        <Form
          className={classes.registrationForm}
          name="registration"
        >
          <Field
            id="firstName"
            name="firstName"
            as={TextField}
            required
            autoFocus
            fullWidth
            variant="outlined"
            label="First Name"
            helperText={<ErrorMessage name="firstName" />}
            error={errors.firstName && touched.firstName}
          />
          <Field
            id="lastName"
            name="lastName"
            as={TextField}
            required
            fullWidth
            variant="outlined"
            label="Last Name"
            helperText={<ErrorMessage name="lastName" />}
            error={errors.lastName && touched.lastName}
          />
          <CountryTypeahead
            countryMapping={countryMapping}
            selected={values.country}
            isMulti={false}
            withMargin={false}
            required={false}
            helperText={<ErrorMessage name="country" />}
            error={Boolean(errors.country && touched.country)}
            onChange={(val: string) => {
              setFieldValue('country', val)
              setIsUSA(val === 'USA')
              setIsCanada(val === 'CAN')

              // Reset city and state when country changes
              setFieldValue('city', initialValue.city)
              setFieldValue('state', initialValue.state)
            }}
            onBlur={() => setFieldTouched('country', true)}
          />
          <Field
            id="city"
            name="city"
            as={TextField}
            fullWidth
            variant="outlined"
            label="City"
          />
          {isCanadaOrUSA && (
            <>
              <Autocomplete
                id="state"
                options={isCanada ? provinces : states}
                getOptionLabel={(option: State | { name: string; value: string }) => option.name}
                onChange={(e, value) => {
                  setFieldValue('state', value ? value.value : initialValue.state)
                }}
                onBlur={() => setFieldTouched('state', true)}
                renderInput={(params) => (
                  <TextField
                    label={isCanada ? 'Province' : 'State'}
                    variant="outlined"
                    name="state"
                    error={Boolean(errors.state && touched.state)}
                    helperText={<ErrorMessage name="state" />}
                    {...params}
                  />
                )}
              />
            </>
          )}
          {!isCanadaOrUSA && (
            <Field
              id="state"
              name="state"
              as={TextField}
              fullWidth
              variant="outlined"
              label="State/Province"
            />
          )}
          <Field
            type="password"
            id="pass"
            name="password.pass"
            as={TextField}
            required
            fullWidth
            variant="outlined"
            label="Password"
            helperText={
              errors.password?.pass && touched.password?.pass ? (
                <ErrorMessage name="password.pass" />
              ) : (
                'Your password must be at least 8 characters.'
              )
            }
            error={errors.password?.pass && touched.password?.pass}
          />
          <Field
            type="password"
            id="passConfirm"
            name="password.passConfirm"
            as={TextField}
            required
            fullWidth
            variant="outlined"
            label="Confirm Password"
            helperText={<ErrorMessage name="password.passConfirm" />}
            error={errors.password?.passConfirm && touched.password?.passConfirm}
          />
          <ReCAPTCHA
            className={classes.centerAlign}
            ref={recaptchaRef}
            sitekey={window.RECAPTCHA_SITE_KEY}
            onChange={() => {
              setFieldValue('recaptchaResponse', recaptchaRef.current?.getValue(), true)
            }}
          />
          <TermsAndPrivacy mb={0} />
          {confirmError && (
            <Typography
              role="alert"
              color="error"
            >
              An error occurred while signing up, please try again later.
            </Typography>
          )}
          <Button
            type="submit"
            color="primary"
            variant="contained"
            disabled={!isValid || isSubmitting || values.recaptchaResponse === ''}
          >
            Log In
          </Button>
        </Form>
      )}
    </Formik>
  )
}

export default ParentRegistrationForm
