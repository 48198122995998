import React, { useContext, useCallback, useEffect } from 'react'

import qs from '@scoir/query-string'

import getParams, { clear } from './local-store'

interface IQueryParamsContext {
  params: string
  onClear(): void
  [key: string]: any
}

const DEFAULT_STORED_QUERY_PARAMS = ''
const Context = React.createContext({ params: DEFAULT_STORED_QUERY_PARAMS } as IQueryParamsContext)

const useFromContext = (field: string) => {
  const ctx = useContext(Context)
  if (!ctx) throw new Error(`use${field} must be used within a QueryParamsProvider`)
  const value = ctx[field] as string | ((...args: unknown[]) => void)
  return value
}

export const useQueryParams = () => {
  const params = useFromContext('params') as string
  return qs.parse(params)
}

export const useOnClear = () => {
  const onClear = useFromContext('onClear') as (...args: unknown[]) => void
  return onClear
}

interface IQueryParamsProviderProps {
  children: any
}

export const QueryParamsProvider = ({ children }: IQueryParamsProviderProps) => {
  const [queryParams, setQueryParams] = React.useState(DEFAULT_STORED_QUERY_PARAMS)

  useEffect(() => {
    const params = getParams()
    setQueryParams(params)
  }, [])

  const onClear = useCallback(() => {
    clear()
    setQueryParams(DEFAULT_STORED_QUERY_PARAMS)
  }, [])

  return <Context.Provider value={{ params: queryParams, onClear } as IQueryParamsContext}>{children}</Context.Provider>
}

export const useClickTrackingQueryParams = () => {
  const { ClickLabel, ClickLocation, PreviousPageUrl, scid } = useQueryParams()
  return {
    clickLabel: ClickLabel,
    clickLocation: ClickLocation,
    previousPageUrl: PreviousPageUrl,
    scid: scid,
  }
}
export const useClickTrackingQueryParamsString = () => {
  return qs.stringify(useClickTrackingQueryParams())
}

export const useClearClickTrackingQueryParams = () => {
  const onClear = useOnClear()
  return useCallback(() => {
    onClear()
    clear()
  }, [onClear])
}

export default QueryParamsProvider
