import React from 'react'

import { ErrorReporter } from '@scoir/error-reporter'

import { get } from 'lodash'
import api from 'common/api'

export const NetworkErrorReporter = (props) => (
  <ErrorReporter
    {...props}
    onError={(payload) => {
      api.post('/api/errors', {
        Message: payload,
        Context: {
          HttpRequest: {
            URL: document.location.href,
            UserAgent: get(navigator, 'userAgent'),
          },
        },
        ServiceContext: {
          Service: 'frontend',
        },
      })
    }}
  />
)
export default NetworkErrorReporter
