import React from 'react'

import { Theme, Typography, makeStyles } from '@material-ui/core'

const useStyles = makeStyles<Theme, { mb?: number }>((theme) => ({
  termsAndPrivacy: {
    marginBottom: ({ mb = 2 }) => theme.spacing(mb),
  },
  link: {
    color: theme.palette.primary.dark,
    textDecoration: 'underline',
  },
}))

const TermsAndPrivacy = ({ mb }: { mb?: number }) => {
  const classes = useStyles({ mb })
  return (
    <Typography
      color="textSecondary"
      className={classes.termsAndPrivacy}
    >
      By creating an account you are agreeing to Scoir’s{' '}
      <a
        className={classes.link}
        href="https://www.scoir.com/terms-of-service"
        target="_blank"
        rel="noopener noreferrer"
      >
        Terms of Service
      </a>{' '}
      and{' '}
      <a
        className={classes.link}
        href="https://www.scoir.com/privacy-policy"
        target="_blank"
        rel="noopener noreferrer"
      >
        {' '}
        Privacy Policy
      </a>
    </Typography>
  )
}

export default TermsAndPrivacy
