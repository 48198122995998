import api from 'common/api'

export const unsubscribe = (Reason, OtherReason, MessageTag, UnsubscribeID) =>
  api.post(`/api/email/unsubscribe`, {
    Reason,
    OtherReason,
    MessageTag,
    UnsubscribeID,
  })
export const resubscribe = (MessageTag, UnsubscribeID) =>
  api.post('/api/email/resubscribe', {
    MessageTag,
    UnsubscribeID,
  })
