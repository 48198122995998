import * as Yup from 'yup'
import { sub } from 'date-fns'

export const minGradYear = new Date().getFullYear() - 100
export const maxGradYear = new Date().getFullYear() + 10

// Standard linked student validation. Location is not collected
export const studentYupValidation = Yup.object().shape({
  firstName: Yup.string().required('This is Required.'),
  lastName: Yup.string().required('This is Required.'),
  gradYear: Yup.number()
    .integer(`Please enter a proper year between ${minGradYear} and ${maxGradYear}.`)
    .moreThan(minGradYear - 1, `Please enter a proper year after ${minGradYear}.`)
    .lessThan(maxGradYear + 1, `Please enter a proper year before ${maxGradYear}.`)
    .required(`Please enter a proper year between ${minGradYear} and ${maxGradYear}.`),
  birthdate: Yup.date()
    .typeError('Must be formatted to mm/dd/yyyy')
    .min(sub(new Date(), { years: 100 }), 'Please enter a valid age.')
    .max(sub(new Date(), { years: 13 }), 'You must be at least 13 years of age or older.')
    .required('This is Required.'),
  password: Yup.object().shape({
    pass: Yup.string()
      .min(8, 'Your password must be at least 8 characters.')
      .required('Your password must be at least 8 characters.'),
    passConfirm: Yup.string()
      .oneOf([Yup.ref('pass')], 'Your passwords must match.')
      .required('Your passwords must match.'),
  }),
  confirmTOS: Yup.boolean().required().oneOf([true]),
})

// Validation for off the street students (Other HS) and grads from the USA
// City and State are required
export const otsStudentYupValidation = Yup.object().shape({
  firstName: Yup.string().required('This is Required.'),
  lastName: Yup.string().required('This is Required.'),
  gradYear: Yup.number()
    .integer(`Please enter a proper year between ${minGradYear} and ${maxGradYear}.`)
    .moreThan(minGradYear - 1, `Please enter a proper year after ${minGradYear}.`)
    .lessThan(maxGradYear + 1, `Please enter a proper year before ${maxGradYear}.`)
    .required(`Please enter a proper year between ${minGradYear} and ${maxGradYear}.`),
  birthdate: Yup.date()
    .typeError('Must be formatted to mm/dd/yyyy')
    .min(sub(new Date(), { years: 100 }), 'Please enter a valid age.')
    .max(sub(new Date(), { years: 13 }), 'You must be at least 13 years of age or older.')
    .required('This is Required.'),
  password: Yup.object().shape({
    pass: Yup.string()
      .min(8, 'Your password must be at least 8 characters.')
      .required('Your password must be at least 8 characters.'),
    passConfirm: Yup.string()
      .oneOf([Yup.ref('pass')], 'Your passwords must match.')
      .required('Your passwords must match.'),
  }),
  confirmTOS: Yup.boolean().required().oneOf([true]),
  city: Yup.string().required('This is Required.'),
  stateOrProvince: Yup.string().required('This is Required.'),
  country: Yup.string().required('This is Required.'),
})

// Validation for international OTS students (Other HS) and grads
// City and State are optional
export const internationalStudentYupValidation = Yup.object().shape({
  firstName: Yup.string().required('This is Required.'),
  lastName: Yup.string().required('This is Required.'),
  gradYear: Yup.number()
    .integer(`Please enter a proper year between ${minGradYear} and ${maxGradYear}.`)
    .moreThan(minGradYear - 1, `Please enter a proper year after ${minGradYear}.`)
    .lessThan(maxGradYear + 1, `Please enter a proper year before ${maxGradYear}.`)
    .required(`Please enter a proper year between ${minGradYear} and ${maxGradYear}.`),
  birthdate: Yup.date()
    .typeError('Must be formatted to mm/dd/yyyy')
    .min(sub(new Date(), { years: 100 }), 'Please enter a valid age.')
    .max(sub(new Date(), { years: 13 }), 'You must be at least 13 years of age or older.')
    .required('This is Required.'),
  password: Yup.object().shape({
    pass: Yup.string()
      .min(8, 'Your password must be at least 8 characters.')
      .required('Your password must be at least 8 characters.'),
    passConfirm: Yup.string()
      .oneOf([Yup.ref('pass')], 'Your passwords must match.')
      .required('Your passwords must match.'),
  }),
  confirmTOS: Yup.boolean().required().oneOf([true]),
  country: Yup.string().required('This is Required.'),
  city: Yup.string(),
  stateOrProvince: Yup.string(),
})

export const isValidDate = (date: string) => {
  return date && date !== '0001-01-01'
}
