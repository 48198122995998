import React from 'react'

import { get } from 'lodash'

import { useRegistrationData } from '../../SignUpProvider/sign-up-context'

import Title from 'components/Title'

export const StudentInvite = () => {
  const data = useRegistrationData()
  const email = get(data, 'matchedStudent.Email')
  return (
    <Title
      title="Check your email 📬"
      subtitle={`Check ${email ? email : 'your email'} to verify your email and activate your account.`}
    />
  )
}

export const UnmatchedStudentInvite = () => {
  const data = useRegistrationData()
  const email = get(data, 'matchedStudent.Email')
  return (
    <Title
      title="Check your email 📬"
      subtitle={`You’re not currently linked to a high school, but you can still use Scoir. Check ${
        email ? email : 'your email'
      } to verify your email and activate your account.`}
    />
  )
}
