import React, { useEffect, useState } from 'react'

import { FormikHelpers } from 'formik'
import { makeStyles } from '@material-ui/core'
import { Link } from 'react-router-dom'
import cookie from 'cookie'
import moment from 'moment'

import ComponentLoader from '@scoir/components-core/dist/components/ComponentLoader'

import api from 'common/api'
import { clearLocalStorage, routeToApp, setCookies } from 'common/login-service'
import { AuthToken, ConfirmResponse } from 'types/@scoir/apiTypes'
import { formatter } from '@scoir/date'

import Title from 'components/Title'
import RegistrationForm, { RegistrationFormValues } from 'components/RegistrationForm'
import { isValidDate } from 'components/RegistrationForm/helper'

const useStyles = makeStyles((theme) => ({
  link: {
    color: theme.palette.primary.dark,
    textDecoration: 'underline',
    margin: theme.spacing(2, 0),
    display: 'inline-block',
    cursor: 'pointer',
  },
}))

const GuidanceOrgRegisterRoute = ({ token }: { token: string }) => {
  const classes = useStyles()
  const [tokenAuth, setTokenAuth] = React.useState<AuthToken | undefined>()
  const [error, setError] = useState<string>()
  const [loading, setLoading] = useState(true)
  const [confirmError, setConfirmError] = useState(false)
  const [initialValues, setInitialValues] = useState<RegistrationFormValues | undefined>()

  useEffect(() => {
    api
      .post<unknown, AuthToken>(`/api/guidance_org/students/invite/validate/${token}`, {})
      .then((auth) => {
        setTokenAuth(auth)
        setInitialValues({
          firstName: auth.Student?.FirstName || '',
          lastName: auth.Student?.LastName || '',
          password: {
            pass: '',
            passConfirm: '',
          },
          birthdate: auth.BirthDate && isValidDate(auth.BirthDate) ? formatter.format(auth.BirthDate) : undefined,
          gradYear:
            auth.Student?.GraduationYear && auth.Student.GraduationYear > 0
              ? auth.Student.GraduationYear
              : new Date().getFullYear() + 1,
          confirmTOS: false,
          recaptchaResponse: '',
        })
        setLoading(false)
      })
      .catch((err) => {
        const errMsg =
          err.status === 401
            ? 'This Scoir Invitation is no longer valid.'
            : 'An unexpected error occurred, please try again later.'
        setError(errMsg)
        setLoading(false)
      })
  }, [token])

  const clearCookies = () => {
    const path = `/student/confirm/guidance-org/${tokenAuth?.GuidanceOrgId}/link/${token}`
    document.cookie = cookie.serialize('remembered', path, {
      path: '/',
    })
  }

  const registerStudentUser = (values: RegistrationFormValues, props: FormikHelpers<RegistrationFormValues>) => {
    props.setSubmitting(true)
    const body = {
      ...tokenAuth,
      FirstName: values.firstName,
      LastName: values.lastName,
      BirthDate: moment(values.birthdate!).format('YYYY-MM-DD'),
      GraduationYear: values.gradYear,
      Category: 'High School Student',
      Password: {
        Pass: values.password.pass,
        PassConfirm: values.password.passConfirm,
      },
      RecaptchaResponse: values.recaptchaResponse,
      HighSchoolStudentId: tokenAuth?.MatchedHighSchoolStudent?.HighSchoolStudentId ?? null,
    }

    api
      .post<unknown, ConfirmResponse>('/api/guidance_org/students/invite/confirm', body)
      .then((loginResponse) => {
        if (loginResponse.access_token && loginResponse.UserTypes) {
          const userType = loginResponse.UserTypes[0]
          clearLocalStorage(userType)
          setCookies(userType, loginResponse.access_token)
          routeToApp(userType)
        } else {
          setConfirmError(true)
          props.setSubmitting(false)
        }
      })
      .catch(() => {
        setConfirmError(true)
        props.setSubmitting(false)
      })
  }

  if (error) {
    return <Title subtitle={error} />
  }
  return (
    <ComponentLoader loaded={true || !loading}>
      <Title
        title="Welcome to Scoir ✨"
        subtitle={`Please complete your registration ${tokenAuth?.Email ? `for ${tokenAuth.Email}` : ''}`}
      />
      <Link
        to={`/student/confirm/guidance-org/${tokenAuth?.GuidanceOrgId}/link/${token}`}
        onClick={clearCookies}
        className={classes.link}
      >
        Sign in with an existing Scoir account
      </Link>
      <RegistrationForm
        initialValue={initialValues}
        handleSubmit={registerStudentUser}
        useCaptcha
        confirmError={confirmError}
      />
    </ComponentLoader>
  )
}

export default GuidanceOrgRegisterRoute
