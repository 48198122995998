import React from 'react'

import CoalitionSignUp from './CoalitionSignUp'
import SignUpProvider from './SignUpProvider'
import SignUpStep from './SignUpStep'

type SignUpProps = {
  highSchoolCeebCode?: string
  source?: string
  scid?: number
}

const SignUp = ({ highSchoolCeebCode, source, scid }: SignUpProps) => {
  if (source) {
    return <CoalitionSignUp scid={scid} />
  }
  return (
    <SignUpProvider highSchoolCeebCode={highSchoolCeebCode}>
      <SignUpStep />
    </SignUpProvider>
  )
}

export default SignUp
