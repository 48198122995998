import React from 'react'
import { get } from 'lodash'
import { useRegistrationData } from '../../SignUpProvider/sign-up-context'
import Title from 'components/Title'
import { Button, makeStyles } from '@material-ui/core'

const useStyles = makeStyles(() => ({
  button: {
    borderRadius: 40,
    width: '100%',
  },
}))

const CleverSyncError = () => {
  const data = useRegistrationData()
  const orgName = get(data, 'highSchool.HighSchoolName')
  const subject = `Unable to connect to ${orgName}`
  const body = `Hi Scoir Support,%0D%0A I cannot currently create a Scoir account at ${orgName} using Clever.`
  const classes = useStyles()
  return (
    <>
      <Title
        title="We’re having trouble syncing your Clever profile"
        subtitle="Please contact our team and we’ll help you sort it out."
      />
      <Button
        href={`mailto:support@scoir.com?subject=${subject}&body=${body}`}
        variant="contained"
        color="primary"
        className={classes.button}
      >
        Contact Support
      </Button>
    </>
  )
}

export default CleverSyncError
