import React, { useMemo } from 'react'
import PropTypes from 'prop-types'

import { Chip, TextField } from '@material-ui/core'
import { Autocomplete } from '@material-ui/lab'
import { createFilterOptions } from '@material-ui/lab/Autocomplete'
import { map, get } from 'lodash'

// support matching against alpha3 or name
const autcompleteFilter = createFilterOptions({
  stringify: (option) => `${get(option, 'Alpha3')}-${get(option, 'Name')}`,
})

const mapCountryAlphaToOption = (countryMapping, alpha3) => get(countryMapping, alpha3, alpha3)

const CountryTypeahead = ({
  countryMapping,
  selected,
  onChange,
  id = 'country',
  label = 'Country',
  isMulti,
  withMargin,
  error,
  helperText,
  idPrefix = '',
  disabled = false,
  required,
  ...rest
}) => {
  const countries = useMemo(() => map(countryMapping), [countryMapping])
  const selectedValues = useMemo(() => {
    return isMulti
      ? map(selected, (alpha3) => mapCountryAlphaToOption(countryMapping, alpha3))
      : mapCountryAlphaToOption(countryMapping, selected)
  }, [selected, countryMapping, isMulti])
  return (
    <Autocomplete
      disabled={disabled}
      {...rest}
      aria-label={label}
      multiple={isMulti}
      clearOnBlur
      options={countries}
      filterOptions={autcompleteFilter}
      getOptionLabel={(option) => get(option, 'Name', option)}
      getOptionSelected={(option, value) => get(option, 'Alpha3') === get(value, 'Alpha3', value)}
      value={selectedValues || null}
      id={idPrefix ? `${idPrefix}-${id}` : id}
      ChipProps={isMulti ? { color: 'primary', variant: 'outlined' } : undefined}
      onChange={(e, selected) => {
        onChange(isMulti ? map(selected, (country) => get(country, 'Alpha3', country)) : get(selected, 'Alpha3'))
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          error={error}
          helperText={helperText}
          margin={withMargin ? 'normal' : undefined}
          label={label}
          variant="outlined"
          name={id}
          required={required}
        />
      )}
      renderTags={
        isMulti
          ? (tagValue, getTagProps) =>
              tagValue.map((option, index) => (
                <Chip
                  key={option}
                  aria-label={get(option, 'Name', option) + ' click to remove'}
                  label={get(option, 'Name', option)}
                  value={option}
                  color={'primary'}
                  variant={'outlined'}
                  {...getTagProps({ index })}
                />
              ))
          : undefined
      }
    />
  )
}
CountryTypeahead.propTypes = {
  withMargin: PropTypes.bool,
  countryMapping: PropTypes.object.isRequired,
  selected: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
  onChange: PropTypes.func.isRequired,
  isMulti: PropTypes.bool,
  id: PropTypes.string,
  label: PropTypes.string,
  error: PropTypes.bool,
  helperText: PropTypes.object,
  idPrefix: PropTypes.string,
  disabled: PropTypes.bool,
  required: PropTypes.bool,
}

export default CountryTypeahead
