import React, { useState } from 'react'
import { Button, Typography, makeStyles } from '@material-ui/core'

import api from 'common/api'
import {
  useRegistrationData,
  useSendInvite,
  useConfirmMatchToCleverSchool,
  useConfirmMatchToClassLinkSchool,
} from '../SignUpProvider/sign-up-context'
import Title from 'components/Title'

const useStyles = makeStyles((theme) => ({
  buttonGroup: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2),
  },
}))

export const CleverRequiredErrorMessage =
  "Can't create an account with that email. Try creating an account with Clever instead."
export const ClassLinkRequiredErrorMessage =
  "Can't create an account with that email. Try creating an account with ClassLink instead."
export const EmailAlreadyExistsErrorMessage =
  'An account with this email already exists. If you don’t remember your password, try resetting it.'

const MatchConfirm = () => {
  const classes = useStyles()
  const data = useRegistrationData()
  const [error, setError] = useState<string>()
  const sendInvite = useSendInvite()
  const confirmMatchToCleverSchool = useConfirmMatchToCleverSchool()
  const confirmMatchToClassLinkSchool = useConfirmMatchToClassLinkSchool()
  const { matchedStudent } = data

  const showError = async (err: any) => {
    const status = err?.status
    let errorMessage = 'An unexpected error occurred, please try again later.'
    const errorBody = err.body ? await err.text() : undefined
    if (status === 403 && errorBody) {
      if (errorBody.includes('clever')) {
        errorMessage = CleverRequiredErrorMessage
      } else if (errorBody.includes('ClassLink')) {
        errorMessage = ClassLinkRequiredErrorMessage
      }
    } else if (status === 409) {
      errorMessage = EmailAlreadyExistsErrorMessage
    }

    setError(errorMessage)
  }
  const sendMatchInvite = (didConfirm: boolean) => {
    const invitee = didConfirm
      ? {
          ...matchedStudent,
          HighSchoolStudentId: matchedStudent?.Id,
          HighSchools: [matchedStudent?.HighSchool],
        }
      : {
          Email: data.email,
          HighSchools: [data.highSchool],
        }
    if (data?.regCode) {
      invitee.StudentIdOrRegCode = data.regCode
    }
    api
      .post('/api/student/register/invite', invitee)
      .then(() => {
        didConfirm ? sendInvite(matchedStudent) : sendInvite({ Email: invitee.Email, unmatched: true })
      })
      .catch(showError)
  }

  const confirmMatch = () => {
    const cleverRequired = data?.matchedStudent?.HighSchool?.CleverRequired || false
    const classLinkRequired = data?.matchedStudent?.HighSchool?.ClassLinkRequired || false
    if (cleverRequired) {
      confirmMatchToCleverSchool()
    } else if (classLinkRequired) {
      confirmMatchToClassLinkSchool()
    } else {
      sendMatchInvite(true)
    }
  }

  const school = data?.matchedStudent?.HighSchool
  const hasLocation = school?.City && school?.State
  const findType = data?.birthDate ? 'account' : 'email address'
  return (
    <>
      <Title
        title={`Your ${findType} matches ${school?.HighSchoolName}${
          hasLocation ? ` in ${school.City}, ${school.State}` : ''
        } 📚`}
      />
      <div className={classes.buttonGroup}>
        {error && (
          <Typography
            role="alert"
            color="error"
          >
            {error}
          </Typography>
        )}
        <Button
          color="primary"
          variant="contained"
          onClick={confirmMatch}
        >
          Continue
        </Button>
        <Button
          color="primary"
          onClick={() => sendMatchInvite(false)}
        >
          This is not my school
        </Button>
      </div>
    </>
  )
}

export default MatchConfirm
