import { Field, ErrorMessage, FormikErrors, FormikTouched } from 'formik'
import { TextField } from '@material-ui/core'
import { Autocomplete } from '@material-ui/lab'

import { states } from '@scoir/states'
import provinces from './provinces'
import { RegistrationFormValues } from '.'

type State = {
  name: string
  value: string
  region: string
  tokens: string[]
}

type CityStateFieldsProps = {
  country: string | undefined
  hasGraduated?: boolean
  values: RegistrationFormValues
  errors: FormikErrors<RegistrationFormValues>
  touched: FormikTouched<RegistrationFormValues>
  setFieldValue: (
    field: string,
    value: any,
    shouldValidate?: boolean
  ) => Promise<void | FormikErrors<RegistrationFormValues>>
  setFieldTouched: (field: string, isTouched?: boolean | undefined, shouldValidate?: boolean | undefined) => void
}

const mapStateToOption = (options: State[], selected?: string) => {
  if (!selected) {
    return null
  }
  return options.find((option) => option.value === selected)
}

const CityStateFields = ({
  country,
  values,
  errors,
  touched,
  setFieldValue,
  setFieldTouched,
}: CityStateFieldsProps) => {
  const isUSA = country === 'USA'
  const isCanada = country === 'CAN'
  const hasStateAutocomplete = isUSA || isCanada
  const stateOptions = isCanada ? provinces : states
  // @ts-expect-error typing
  const selectedState = mapStateToOption(stateOptions, values.stateOrProvince)

  // City/State are required for USA students and optional for international students
  return (
    <>
      <Field
        id="city"
        name="city"
        as={TextField}
        fullWidth
        variant="outlined"
        label="City"
        required={isUSA}
        helperText={<ErrorMessage name="city" />}
        error={errors.city && touched.city}
      />
      {!hasStateAutocomplete && (
        <Field
          id="stateOrProvince"
          name="stateOrProvince"
          as={TextField}
          fullWidth
          variant="outlined"
          label="State/Province"
        />
      )}
      {hasStateAutocomplete && (
        <Autocomplete
          id="stateOrProvince"
          value={selectedState}
          // @ts-expect-error typing
          options={stateOptions}
          getOptionLabel={(option: State) => option.name}
          onChange={(e, value) => setFieldValue('stateOrProvince', value?.value)}
          onBlur={() => setFieldTouched('stateOrProvince', true)}
          renderInput={(params) => {
            return (
              <TextField
                label={isCanada ? 'Province' : 'State'}
                variant="outlined"
                name="stateOrProvince"
                error={Boolean(errors.stateOrProvince && touched.stateOrProvince)}
                helperText={<ErrorMessage name="stateOrProvince" />}
                required={isUSA}
                {...params}
              />
            )
          }}
        />
      )}
    </>
  )
}

export default CityStateFields
