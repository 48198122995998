import { ReactNode, useEffect, useMemo, useRef } from 'react'

// @ts-expect-error typing
import { addUrlProps, UrlQueryParamTypes } from 'react-url-query'

type Props = {
  challenge: string
  children?: ReactNode
  clientId: string
  redirectUri: string
  responseType: string
  state: string
  submit?: boolean
}

const OAuthForm = ({ challenge, children, clientId, redirectUri, responseType, state, submit }: Props) => {
  const formRef = useRef<HTMLFormElement>(null)

  useEffect(() => {
    if (submit === true && formRef.current) {
      formRef.current.submit()
    }
  }, [submit, formRef])

  const action = useMemo(() => {
    const q = new URLSearchParams({
      challenge,
      client_id: clientId,
      redirect_uri: redirectUri,
      response_type: responseType,
      state,
    })

    return `/api/oauth2/authorize?${q}`
  }, [challenge, clientId, redirectUri, responseType, state])

  return (
    <form
      ref={formRef}
      action={action}
      method="POST"
    >
      {children}
    </form>
  )
}

const urlPropsQueryConfig = {
  challenge: { type: UrlQueryParamTypes.string, queryParam: 'challenge' },
  clientId: { type: UrlQueryParamTypes.string, queryParam: 'client_id' },
  redirectUri: { type: UrlQueryParamTypes.string, queryParam: 'redirect_uri' },
  responseType: { type: UrlQueryParamTypes.string, queryParam: 'response_type' },
  state: { type: UrlQueryParamTypes.string, queryParam: 'state' },
}

export default addUrlProps({ urlPropsQueryConfig })(OAuthForm)
