import React from 'react'

import { Button, Checkbox, FormControlLabel, makeStyles } from '@material-ui/core'
import ReCAPTCHA from 'react-google-recaptcha'

import Title from 'components/Title'
import TermsAndPrivacy from 'components/TermsAndPrivacy'

const useStyles = makeStyles((theme) => ({
  verifyForm: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2),
  },
  centerAlign: {
    display: 'flex',
    justifyContent: 'center',
  },
}))

type StudentMatchProps = {
  student: {
    FirstName: string
    Organization: {
      Name: string
      City: string
      State: string
    }
  }
  onSignUp: () => void
}

const StudentMatch = ({ student, onSignUp }: StudentMatchProps) => {
  const classes = useStyles()
  const [didVerifyAge, setDidVerifyAge] = React.useState(false)
  const [captchaSuccessful, setCaptchaSuccessful] = React.useState(false)

  const handleAgeVerification = () => {
    setDidVerifyAge(!didVerifyAge)
  }
  const handleCaptcha = () => {
    setCaptchaSuccessful(!captchaSuccessful)
  }

  const org = student.Organization
  const signUp = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault()
    onSignUp()
  }
  return (
    <>
      <Title
        title={`Welcome, ${student.FirstName} ✨`}
        subtitle={`Please activate your account with ${org?.Name} in ${org?.City}, ${org?.State}`}
      />
      <div className={classes.verifyForm}>
        <FormControlLabel
          className={classes.centerAlign}
          label="I certify that I am 13 years of age or older."
          control={
            <Checkbox
              id="confirmTOS"
              disableRipple={false}
              color="primary"
              onChange={handleAgeVerification}
            />
          }
        />
        <ReCAPTCHA
          className={classes.centerAlign}
          sitekey={window.RECAPTCHA_SITE_KEY}
          onChange={handleCaptcha}
        />
        <TermsAndPrivacy mb={0} />
        <Button
          color="primary"
          variant="contained"
          onClick={signUp}
          disabled={!didVerifyAge || !captchaSuccessful}
        >
          Log In
        </Button>
      </div>
    </>
  )
}
export default StudentMatch
