import React from 'react'
import { Formik, Form, Field, FormikHelpers } from 'formik'
import * as Yup from 'yup'
import { Button, TextField, makeStyles } from '@material-ui/core'
import ReCAPTCHA from 'react-google-recaptcha'

const useStyles = makeStyles((theme) => ({
  emailForm: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  centerAlign: {
    display: 'flex',
    justifyContent: 'center',
  },
}))

export type EmailFormValues = {
  email: string
  recaptchaResponse?: string
}

type EmailFormProps = {
  initialValue: EmailFormValues
  handleSubmit: (values: EmailFormValues, props: FormikHelpers<EmailFormValues>) => void
  useCaptcha?: boolean
  error?: string
  submitText?: string
  fieldText?: string
  required?: boolean
}

const EmailForm = ({
  initialValue,
  handleSubmit,
  useCaptcha,
  error,
  submitText = 'Continue',
  fieldText = 'Email',
  required = true,
}: EmailFormProps) => {
  const classes = useStyles()
  const recaptchaRef = React.createRef<ReCAPTCHA>()

  const yupValidation = Yup.object().shape({
    email: Yup.string().email('Please enter a valid email.').required('This is Required.'),
  })

  return (
    <Formik
      initialValues={initialValue}
      validateOnMount
      validationSchema={yupValidation}
      onSubmit={handleSubmit}
    >
      {({ values, errors, touched, isValid, isSubmitting, setFieldValue }) => (
        <Form
          className={classes.emailForm}
          name="email"
        >
          <Field
            id="email"
            name="email"
            as={TextField}
            required={required}
            autoFocus={required}
            fullWidth
            variant="outlined"
            label={fieldText}
            helperText={error}
            error={(errors.email && touched.email) || Boolean(error)}
          />
          {useCaptcha && (
            <ReCAPTCHA
              className={classes.centerAlign}
              ref={recaptchaRef}
              sitekey={window.RECAPTCHA_SITE_KEY}
              onChange={() => {
                setFieldValue('recaptchaResponse', recaptchaRef.current?.getValue(), true)
              }}
            />
          )}
          <Button
            type="submit"
            color="primary"
            variant="contained"
            disabled={!isValid || isSubmitting || (useCaptcha && !values.recaptchaResponse)}
          >
            {submitText}
          </Button>
        </Form>
      )}
    </Formik>
  )
}

export default EmailForm
