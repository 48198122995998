import { useCallback } from 'react'

import { get, isEmpty, keys, filter, forEach, isUndefined } from 'lodash'
import cookie from 'cookie'
import qs from '@scoir/query-string'

import window from 'browser-lib/window'
import api from 'common/api'
import { useClickTrackingQueryParams, useClearClickTrackingQueryParams } from 'components/QueryParamsProvider'
import { getTrackEventsSessionId } from 'common/get-track-events-session-id'
import { COBRAND_ALIAS } from './coalition-sources'

const ROUTE_MAP = {
  Student: '/student/home?interrupt=1',
  Parent: '/parent/home?interrupt=1',
  Counselor: '/counselor/home',
  Advisor: '/guidance/home',
  Admin: '/admin/default',
  AdmissionsOfficer: '/admissions/home',
  CMS: '/admin/cms',
  Teacher: undefined,
} as const

const COOKIE_PATHS_BY_USER_TYPE = {
  Admin: '/admin',
  CMS: '/admin',
  AdmissionsOfficer: '/admissions',
  Counselor: '/counselor',
  Advisor: '/guidance',
  Parent: '/parent',
  Student: '/student',
  Teacher: '/teacher',
} as const

type UserType = keyof typeof COOKIE_PATHS_BY_USER_TYPE

export const clearLocalStorage = (userType: UserType) => {
  const storageKeys = keys(window.localStorage)
  const filtered = filter(storageKeys, (storageKey) => {
    let type = 'admissions'
    if (userType !== 'AdmissionsOfficer') {
      type = userType.toLowerCase()
    }
    const key = `^scoir-${type}-.+`
    const regex = new RegExp(key)
    return regex.test(storageKey)
  })

  forEach(filtered, (key) => {
    window.localStorage.removeItem(key)
  })
}

export const setCookies = (userType: UserType, accessToken: string) => {
  document.cookie = cookie.serialize('bearer', accessToken, {
    path: COOKIE_PATHS_BY_USER_TYPE[userType],
  })
  document.cookie = cookie.serialize('bearer', accessToken, {
    path: '/api/oauth2/authorize',
  })
  document.cookie = cookie.serialize('lastLoginType', userType, { path: '/' })
}

export const routeToApp = (userType: UserType, params: any) => {
  const location = ROUTE_MAP[userType]
  let routeUrl = isUndefined(location) ? '/' : location
  const paramString = qs.stringify(params)
  const alreadyHasParams = routeUrl.includes('?')
  if (!isEmpty(params)) routeUrl += paramString ? `${alreadyHasParams ? '&' : '?'}${paramString}` : ''
  window.location.href = routeUrl
}

export const useLogin = (onSuccess?: () => void) => {
  const clickTrackingQueryParams = useClickTrackingQueryParams()
  const onClearClickTrackingQueryParams = useClearClickTrackingQueryParams()

  return useCallback(
    (userName, password, parameters = '') => {
      const credentials = {
        UserName: userName,
        Password: password,
      }

      const parsedParams = qs.parse(parameters)

      const params = qs.stringify({
        ...clickTrackingQueryParams,
        ...parsedParams,
        sessionId: getTrackEventsSessionId(),
      })

      const routeToAppArgs = {
        referralScid: parsedParams.scid,
        referralSource: get(COBRAND_ALIAS, parsedParams.source, parsedParams.source),
      }

      return (
        api
          .post(`/api/login?${params}`, credentials)
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          .then((data: any) => {
            if (!isEmpty(data.UserTypes)) {
              const userType = data.UserTypes[0]
              clearLocalStorage(userType)
              setCookies(userType, data.access_token)
              if (onSuccess) {
                onSuccess()
              } else {
                routeToApp(userType, routeToAppArgs)
              }
            } else {
              return Promise.resolve({
                data: 'This account does not have any active roles and cannot login.',
              })
            }
            onClearClickTrackingQueryParams()
          })
          .catch((errorResponse) => Promise.reject(errorResponse))
      )
    },
    [clickTrackingQueryParams, onClearClickTrackingQueryParams, onSuccess]
  )
}
