import React from 'react'

import { Box, IconButton, Typography, makeStyles } from '@material-ui/core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRight, faClose } from '@fortawesome/pro-regular-svg-icons'

const useStyles = makeStyles((theme) => ({
  demoBanner: {
    width: '100%',
    height: 90,
    textAlign: 'center',
    display: 'flex',
    backgroundColor: '#b23461',
    color: 'white',
    padding: theme.spacing(2),
    '& h5': {
      fontWeight: 700,
    },
    '& a': {
      color: 'white',
      textDecoration: 'underline',
    },
  },
  main: {
    flex: 1,
    marginLeft: theme.spacing(8),
  },
  remove: {
    width: theme.spacing(5),
    height: theme.spacing(5),
    margin: 'auto',
  },
}))

const DemoBanner = () => {
  const classes = useStyles()
  const [display, setDisplay] = React.useState(true)
  const isLowerEnv = window.location.hostname !== 'app.scoir.com'
  return isLowerEnv && display ? (
    <Box className={classes.demoBanner}>
      <div className={classes.main}>
        <Typography variant="h5">This is a demo environment</Typography>
        <Typography>
          Scoir users this way{' '}
          <FontAwesomeIcon
            size="xs"
            icon={faArrowRight}
          />
          &nbsp;
          <a href="http://app.scoir.com">app.scoir.com</a>
        </Typography>
      </div>
      <IconButton
        className={classes.remove}
        onClick={() => setDisplay(false)}
      >
        <FontAwesomeIcon
          icon={faClose}
          color="#FFF"
        />
      </IconButton>
    </Box>
  ) : null
}

export default DemoBanner
