import api from 'common/api'
import { useClickTrackingQueryParamsString } from 'components/QueryParamsProvider'
import { clearLocalStorage, setCookies, routeToApp } from 'common/login-service'
import { RegisterResponse } from './types'

type ConfirmResponse = {
  ConfirmationCode: string
  Email: string
  FirstName: string
  LastName: string
  Token: string
  UserType: string[]
  Id: string
}

export const validateParentInvite = async (
  id: string,
  code: string,
  setError: any,
  setInitialValues: any,
  setLoading: any,
  setTokenAuth: any,
  setUser: any
) => {
  return api
    .get<ConfirmResponse>(`/api/confirm/${id}/${code}`)
    .then((auth) => {
      setTokenAuth(auth)
      setUser({ email: auth.Email, name: auth.FirstName })
      setInitialValues({
        password: {
          pass: '',
          passConfirm: '',
        },
      })
      setLoading(false)
    })
    .catch(() => {
      setError(true)
      setLoading(false)
    })
}

const registerParentUser = async (
  values: any,
  tokenAuth: any,
  setConfirmError: any,
  setSubmitting: any,
  params: any
) => {
  const query = params ? `?${params}` : ''
  const url = `/api/confirm${query}`
  api
    .post<unknown, RegisterResponse>(url, {
      ...tokenAuth,
      isValid: true,
      Password: {
        Pass: values.password.pass,
        PassConfirm: values.password.passConfirm,
      },
    })
    .then((res) => {
      if (res.access_token && res.UserTypes[0]) {
        const userType = res.UserTypes[0]
        clearLocalStorage(userType)
        setCookies(userType, res.access_token)
        routeToApp(userType)
      } else {
        setConfirmError(true)
        setSubmitting(false)
      }
    })
    .catch(() => {
      setConfirmError(true)
      setSubmitting(false)
    })
}

export const useRegisterParentUser = () => {
  const params = useClickTrackingQueryParamsString()
  return async (values: any, tokenAuth: any, setConfirmError: any, setSubmitting: any) => {
    return await registerParentUser(values, tokenAuth, setConfirmError, setSubmitting, params)
  }
}
