import window from 'browser-lib/window'

/**
 * This fn returns sessionId from localStorage
 * The SessionId is used in student-web to track public track-events as a unique identifier
 * Using it in auth when registerring and logging in helps track conversions from public pages
 * @returns {string} sessionId or {null}
 */
export const getTrackEventsSessionId = () => {
  return window.localStorage.getItem('sessionId')
}
