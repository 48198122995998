export const COALITION = 'Coalition'
export const COALITION_ALIAS = 'C'
export const COALITION_MEMBER = 'CoalitionMember'
export const COALITION_MEMBER_ALIAS = 'CM'

export const COBRAND_ALIAS = {
  [COALITION_ALIAS]: COALITION,
  [COALITION_MEMBER_ALIAS]: COALITION_MEMBER,
}
export const SOURCE_TO_ALIAS = {
  [COALITION]: COALITION_ALIAS,
  [COALITION_MEMBER]: COALITION_MEMBER_ALIAS,
}
export const SOURCES = [COALITION, COALITION_MEMBER]

const sources = {
  COALITION,
  COALITION_MEMBER,
}
export default sources
