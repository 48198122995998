import React, { useState } from 'react'
import * as Yup from 'yup'

import api from 'common/api'
import { useRegistrationData, useFoundMatch, useSendInvite, useTryOtherEmail } from '../SignUpProvider/sign-up-context'

import { Button, TextField, Typography, makeStyles } from '@material-ui/core'
import { ErrorMessage, Field, Form, Formik, FormikHelpers } from 'formik'
import { format, sub } from 'date-fns'
import DatePicker from 'components/MaterialDatePicker'
import Title from 'components/Title'

const useStyles = makeStyles((theme) => ({
  idDobForm: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2),
  },
}))

export const ERRORS = {
  403: "Can't create an account with that Birthdate and Student Id. Try creating an account with Clever instead.",
  409: 'This account exists in the system. Sign in to your existing account using the sign in form. If you don\'t remember your password, click "Forgot your Password".',
}

type IdDOBFormValues = {
  birthdate?: Date
  schoolId?: string
}

const IdDobMatch = () => {
  const classes = useStyles()

  const data = useRegistrationData()
  const foundMatch = useFoundMatch()
  const sendInvite = useSendInvite()
  const tryOtherEmail = useTryOtherEmail()
  const [error, setError] = useState<string>()

  const initialValues: IdDOBFormValues = {
    birthdate: undefined,
    schoolId: '',
  }

  const yupValidation = Yup.object().shape({
    birthdate: Yup.date()
      .typeError('Must be formatted to mm/dd/yyyy')
      .min(sub(new Date(), { years: 100 }), 'Please enter a valid age.')
      .max(sub(new Date(), { years: 13 }), 'You must be at least 13 years of age or older.')
      .required('This is Required.'),
    schoolId: Yup.string().required('This is Required.'),
  })

  const sendMatchInvite = (
    props: FormikHelpers<IdDOBFormValues>,
    matched: boolean,
    inviteData: { Email: string; BirthDate: string; HighSchools: any[] },
    dataUpdate: any = {}
  ) => {
    api
      .post('/api/student/register/invite', inviteData)
      .then(() => {
        !matched ? sendInvite({ Email: inviteData.Email, unmatched: true }) : sendInvite(dataUpdate.matchedStudent)
        props.setSubmitting(false)
      })
      .catch(() => {
        setError('An error occured while inviting user')
        props.setSubmitting(false)
      })
  }

  const handleSubmit = (values: IdDOBFormValues, props: FormikHelpers<IdDOBFormValues>) => {
    props.setSubmitting(true)

    const unlinkedInvite = {
      Email: data.email,
      BirthDate: format(values.birthdate!, 'yyyy-MM-dd'),
      HighSchools: [data.highSchool],
    }
    const id = values.schoolId
    const req = {
      BirthDate: format(values.birthdate!, 'yyyy-MM-dd'),
      StudentIdOrRegCode: id,
      HighSchoolId: data.highSchool?.HighSchoolId,
    }
    api
      .post('/api/student/register/match', req)
      .then((matchedStudent: any) => {
        if (!matchedStudent) {
          sendMatchInvite(props, false, unlinkedInvite)
        }
        if (!matchedStudent.HighSchool) {
          const invitee = {
            ...matchedStudent,
            Email: matchedStudent.Email || data.email,
            Highschool: matchedStudent.HighSchool || data.highSchool,
            StudentIdOrRegCode: id,
            HighSchoolStudentId: matchedStudent.Id,
            HighSchools: [matchedStudent.HighSchool || data.highSchool],
          }
          sendMatchInvite(props, true, invitee, { matchedStudent })
        } else {
          foundMatch({ birthDate: format(values.birthdate!, 'yyyy-MM-dd') }, matchedStudent)
          props.setSubmitting(false)
        }
      })
      .catch((err) => {
        const errorMsg = ERRORS[err.status as 403 | 409]
        if (errorMsg) {
          setError(errorMsg)
          props.setSubmitting(false)
        } else {
          sendMatchInvite(props, false, unlinkedInvite)
        }
      })
  }

  return (
    <div>
      <Title
        title="One more step"
        subtitle={`Your email didn’t match ${data?.highSchool?.HighSchoolName}. Let’s try to find you another way.`}
      />
      <Formik
        initialValues={initialValues}
        validateOnMount
        validationSchema={yupValidation}
        onSubmit={handleSubmit}
      >
        {({ errors, touched, isValid, isSubmitting, setFieldValue, setFieldTouched }) => (
          <Form
            className={classes.idDobForm}
            name="idDobMatch"
          >
            <Field name="birthdate">
              {({ field }: any) => (
                <DatePicker
                  pickerIdSuffix="birthdate"
                  date={field.value}
                  onChange={(updatedDate: any) => {
                    setFieldValue('birthdate', updatedDate, true)
                  }}
                  onBlur={() => {
                    setFieldTouched('birthdate', true, true)
                  }}
                  label="Date of Birth"
                  id="birthdate"
                  format="MM/dd/yyyy"
                  placeholder="mm/dd/yyyy"
                  maxDate={sub(new Date(), { years: 13 })}
                  helperText={
                    errors.birthdate && touched.birthdate ? (
                      <ErrorMessage name="birthdate" />
                    ) : (
                      'You must be at least 13 years of age or older.'
                    )
                  }
                  error={Boolean(errors.birthdate) && touched.birthdate}
                />
              )}
            </Field>
            <Field
              id="schoolId"
              name="schoolId"
              as={TextField}
              fullWidth
              variant="outlined"
              label="School Issued ID Number"
              helperText={<ErrorMessage name="schoolId" />}
              error={errors.schoolId && touched.schoolId}
            />
            {error && (
              <Typography
                role="alert"
                color="error"
              >
                {error}
              </Typography>
            )}
            <Button
              type="submit"
              color="primary"
              variant="contained"
              disabled={!isValid || isSubmitting}
            >
              Continue
            </Button>
            <Button
              color="primary"
              onClick={tryOtherEmail}
            >
              Use Another Email
            </Button>
          </Form>
        )}
      </Formik>
    </div>
  )
}

export default IdDobMatch
