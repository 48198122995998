import React from 'react'

import { connect } from 'react-redux'
import qs from '@scoir/query-string'

import { actions as snackbarActions } from 'store/snackbar'
import window from 'browser-lib/window'
import api from 'common/api'
import { useClickTrackingQueryParams, useClearClickTrackingQueryParams } from 'components/QueryParamsProvider'
import { useTrackEventsSessionId } from 'common/use-track-events-session-id'
import classLinkIcon from './classlink-icon.png'
import StyledButton from '../styledButton'

export const VALID_REDIRECT_URIS = {
  classLinkLogin: 'classlink_login',
}

export const MESSAGE_KEY = 'scoir.classLinkSignUpResult'
export const MESSAGE_LISTENER_EXPIRATION = 300000

type LoginWithClassLinkButtonProps = {
  addSnack: (message: string) => void
  scid?: number
  source?: string
}

const LoginWithClassLinkButton = ({ addSnack, scid, source }: LoginWithClassLinkButtonProps) => {
  const [isRedirecting, setIsRedirecting] = React.useState(false)
  const sessionId = useTrackEventsSessionId()
  const clickTrackingQueryParams = useClickTrackingQueryParams()
  const onClearClickTrackingQueryParams = useClearClickTrackingQueryParams()

  const oauthInit = () => {
    const l = window.location
    const query = qs.stringify({
      ...clickTrackingQueryParams,
      scid: scid && scid > 0 ? scid.toString() : undefined || clickTrackingQueryParams.scid,
      source,
      sessionId,
      redirect_uri: `${l.protocol}//${l.host}/${VALID_REDIRECT_URIS.classLinkLogin}`,
    })

    api
      .get<string>(`/api/oauth/init/classlink?${query}`)
      .then((uri) => {
        const decodedUrl = decodeURIComponent(uri.replace(/"/g, '')).replace(/\\u0026/g, '&')
        setIsRedirecting(true)
        window.location.href = decodedUrl
        onClearClickTrackingQueryParams()
      })
      .catch(() => addSnack('An error occured while signing in with ClassLink.'))
  }

  return (
    <StyledButton
      onClick={oauthInit}
      disabled={isRedirecting}
      icon={classLinkIcon}
      ssoTitle="ClassLink"
    />
  )
}

export default connect(null, {
  addSnack: snackbarActions.addSnackbarItem,
})(LoginWithClassLinkButton)
