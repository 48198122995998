import React from 'react'
import { Formik, Form, Field, ErrorMessage, FormikHelpers } from 'formik'
import { Button, TextField, Typography, makeStyles } from '@material-ui/core'
import * as Yup from 'yup'
import ReCAPTCHA from 'react-google-recaptcha'

import TermsAndPrivacy from 'components/TermsAndPrivacy'

const useStyles = makeStyles((theme) => ({
  passwordForm: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2),
  },
  selectField: {
    textAlign: 'left',
  },
  centerAlign: {
    display: 'flex',
    justifyContent: 'center',
  },
}))

export type PasswordFormValues = {
  password: {
    pass: string
    passConfirm: string
  }
  confirmTOS?: boolean
  recaptchaResponse?: string
}

type PasswordFormProps = {
  initialValue?: PasswordFormValues
  handleSubmit: (values: PasswordFormValues, props: FormikHelpers<PasswordFormValues>) => void
  useCaptcha?: boolean
  confirmError?: boolean
}

const PasswordForm = ({ initialValue, handleSubmit, useCaptcha, confirmError }: PasswordFormProps) => {
  const classes = useStyles()
  const recaptchaRef = React.createRef<ReCAPTCHA>()

  const yupValidation = Yup.object().shape({
    password: Yup.object().shape({
      pass: Yup.string()
        .min(8, 'Your password must be at least 8 characters.')
        .required('Your password must be at least 8 characters.'),
      passConfirm: Yup.string()
        .oneOf([Yup.ref('pass')], 'Your passwords must match.')
        .required('Your passwords must match.'),
    }),
  })

  if (!initialValue) {
    return null
  }
  return (
    <Formik
      initialValues={initialValue}
      validateOnMount
      validationSchema={yupValidation}
      onSubmit={handleSubmit}
    >
      {({ values, errors, touched, isValid, isSubmitting, setFieldValue }) => (
        <Form
          className={classes.passwordForm}
          name="password"
        >
          <Field
            type="password"
            id="pass"
            name="password.pass"
            as={TextField}
            required
            fullWidth
            variant="outlined"
            label="Password"
            helperText={
              errors.password?.pass && touched.password?.pass ? (
                <ErrorMessage name="password.pass" />
              ) : (
                'Your password must be at least 8 characters.'
              )
            }
            error={errors.password?.pass && touched.password?.pass}
          />
          <Field
            type="password"
            id="passConfirm"
            name="password.passConfirm"
            as={TextField}
            required
            fullWidth
            variant="outlined"
            label="Confirm Password"
            helperText={<ErrorMessage name="password.passConfirm" />}
            error={errors.password?.passConfirm && touched.password?.passConfirm}
          />
          {useCaptcha && (
            <ReCAPTCHA
              className={classes.centerAlign}
              ref={recaptchaRef}
              sitekey={window.RECAPTCHA_SITE_KEY}
              onChange={() => {
                setFieldValue('recaptchaResponse', recaptchaRef.current?.getValue(), true)
              }}
            />
          )}
          <TermsAndPrivacy mb={0} />
          {confirmError && (
            <Typography
              role="alert"
              color="error"
            >
              An error occurred while signing up, please try again later.
            </Typography>
          )}
          <Button
            type="submit"
            color="primary"
            variant="contained"
            disabled={!isValid || isSubmitting || (useCaptcha && values.recaptchaResponse === '')}
          >
            Log In
          </Button>
        </Form>
      )}
    </Formik>
  )
}

export default PasswordForm
