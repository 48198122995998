import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'

import api from 'common/api'

import ComponentLoader from '@scoir/components-core/dist/components/ComponentLoader'

import signUpContext from './sign-up-context'
import studentSignupReducer, { types } from './student-signup-reducer'

const SignUpProvider = ({ highSchoolCeebCode, children }) => {
  const [isLoading, setIsLoading] = useState(true)
  const initialState = {
    data: {},
    signUpStep: 'SelectHighSchool',
  }
  const [state, dispatch] = React.useReducer(studentSignupReducer, initialState)

  const value = {
    ...state,
    onUpdate: (action) => dispatch(action),
  }
  useEffect(() => {
    if (highSchoolCeebCode) {
      api
        .get(`/api/high_school/ceebCode/${highSchoolCeebCode}`)
        .then((highSchool) => {
          dispatch({
            type: types.SELECT_HIGH_SCHOOL,
            payload: { highSchool },
          })
        })
        .then(() => {
          setIsLoading(false)
        })
        .catch(() => {
          setIsLoading(false)
        })
    } else {
      setIsLoading(false)
    }
  }, [highSchoolCeebCode])

  return (
    <ComponentLoader loaded={!isLoading}>
      <signUpContext.Provider value={value}>{children}</signUpContext.Provider>
    </ComponentLoader>
  )
}
SignUpProvider.propTypes = {
  highSchoolCeebCode: PropTypes.string,
  children: PropTypes.any.isRequired,
}
export default SignUpProvider
