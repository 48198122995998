import { Box, Button, makeStyles, Typography } from '@material-ui/core'
import OAuthForm from '../OAuthForm'
import { useState } from 'react'

type Props = {
  clientName: string
  clientDescription: string
  infoLink: string
}

const useStyles = makeStyles((theme) => ({
  button: {
    margin: theme.spacing(1),
    width: '40px',
  },
  content: {
    margin: theme.spacing(2, 0),
  },
  link: {
    color: theme.palette.primary.dark,
    textDecoration: 'underline',
    margin: theme.spacing(2, 0),
  },
}))

const Consent = ({ clientName, clientDescription, infoLink }: Props) => {
  const [consent, setConsent] = useState<string>()
  const classes = useStyles()

  return (
    <>
      <OAuthForm submit={consent != undefined}>
        <input
          type="hidden"
          name="consent"
          value={consent}
        />
      </OAuthForm>
      <Typography
        variant="h5"
        align="left"
      >
        Confirm access to your Scoir account
      </Typography>
      <Typography
        className={classes.content}
        variant="body1"
        align="left"
      >
        <p>
          <strong>{clientName}</strong> wants to access your Scoir account.
        </p>
        <p>{clientDescription}</p>
        <p>
          <a
            className={classes.link}
            href={infoLink}
            target="_blank"
            rel="noopener noreferrer"
          >
            Learn more about {clientName}
          </a>
        </p>
        <p>
          By allowing access to your Scoir account, your <strong>name</strong> and <strong>email address</strong> will
          be shared with {clientName}.
        </p>
      </Typography>
      <Box textAlign="right">
        <Button
          className={classes.button}
          type="submit"
          color="primary"
          onClick={() => setConsent('denied')}
        >
          Cancel
        </Button>
        <Button
          className={classes.button}
          type="submit"
          color="primary"
          variant="contained"
          onClick={() => setConsent('accepted')}
        >
          Allow
        </Button>
      </Box>
    </>
  )
}

export default Consent
