import React from 'react'
import { Box, Typography, makeStyles } from '@material-ui/core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircleExclamation } from '@fortawesome/pro-regular-svg-icons'

const useStyles = makeStyles((theme) => ({
  loginError: {
    padding: theme.spacing(2),
    margin: theme.spacing(2, 0),
    background: 'linear-gradient(0deg, rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.9)), #F44336;',
    borderRadius: '4px',
    display: 'flex',
    alignItems: 'center',
    textAlign: 'left',
    gap: theme.spacing(2),
    color: theme.palette.error.main,

    '& a': {
      color: theme.palette.error.main,
      textDecoration: 'underline',
      textDecorationColor: '#ea6846',
      '&:hover, &:focus': {
        textDecorationColor: theme.palette.error.main,
      },
    },
  },
}))

const ErrorBox = ({ error }: { error: React.ReactNode }) => {
  const classes = useStyles()
  return (
    <Box className={classes.loginError}>
      <FontAwesomeIcon
        size="lg"
        icon={faCircleExclamation}
      />
      <Typography
        aria-live="polite"
        color="textPrimary"
        variant="body2"
      >
        {error}
      </Typography>
    </Box>
  )
}

export default ErrorBox
