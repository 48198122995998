import React from 'react'
import { useLocation } from 'react-router-dom'
import { Button, Icon, makeStyles } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  icon: {
    display: 'flex',
    position: 'absolute',
    left: theme.spacing(2),
    marginTop: -2,
    height: theme.spacing(3),
  },
}))

type StyledButtonProps = {
  icon: any
  ssoTitle: string
  onClick: () => void
  disabled?: boolean
}

const StyledButton = ({ icon, ssoTitle, onClick, disabled }: StyledButtonProps) => {
  const classes = useStyles()

  const { pathname } = useLocation()
  const buttonText = pathname.includes('/signin') ? 'Log in' : 'Continue'

  return (
    <Button
      onClick={onClick}
      disabled={disabled}
      startIcon={
        <Icon>
          <img
            className={classes.icon}
            alt={`${ssoTitle} Icon`}
            src={icon}
          />
        </Icon>
      }
      variant="outlined"
      color="primary"
    >
      {`${buttonText} with ${ssoTitle}`}
    </Button>
  )
}

export default StyledButton
