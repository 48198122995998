import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { Box, Typography, makeStyles } from '@material-ui/core'
import { FormikHelpers } from 'formik'

import api from 'common/api'
import Title from 'components/Title'
import TermsAndPrivacy from 'components/TermsAndPrivacy'
import ReturnToLogin from 'components/ReturnToLogin'
import EmailForm, { EmailFormValues } from 'components/EmailForm'

const useStyles = makeStyles((theme) => ({
  link: {
    color: theme.palette.primary.dark,
    textDecoration: 'underline',
  },
}))

const ParentSignUp = () => {
  const classes = useStyles()
  const [emailSent, setEmailSent] = useState(false)
  const [email, setEmail] = useState('')
  const [error, setError] = useState('')

  const handleParentSignUp = (values: EmailFormValues, props: FormikHelpers<EmailFormValues>) => {
    setEmail(values.email)
    props.setSubmitting(true)
    api
      .post('/api/parent/register/signup', {
        email: values.email,
      })
      .then(() => {
        setEmailSent(true)
      })
      .catch((err) => {
        const errMsg =
          err.status === 409
            ? 'An account with this email already exists. If you don’t remember your password, try resetting it.'
            : 'An unexpected error occurred, please try again later.'
        setError(errMsg)
        props.setSubmitting(false)
      })
  }

  if (emailSent) {
    return (
      <Title
        title="Check your email 📬"
        subtitle={`Check ${email} to verify your email and activate your account.`}
      />
    )
  }
  return (
    <Box>
      <Title title="Hey there, Parent/Guardian! 👋" />
      <EmailForm
        initialValue={{ email: '' }}
        handleSubmit={handleParentSignUp}
        error={error}
      />
      <Box>
        <TermsAndPrivacy />
        <Typography>
          <Link
            to="/signup"
            className={classes.link}
          >
            Not a Parent/Guardian?
          </Link>
        </Typography>
        <ReturnToLogin />
      </Box>
    </Box>
  )
}

export default ParentSignUp
