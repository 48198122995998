import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { Box, Typography, makeStyles } from '@material-ui/core'
import { FormikHelpers } from 'formik'

import api from 'common/api'
import Title from 'components/Title'
import TermsAndPrivacy from 'components/TermsAndPrivacy'
import ReturnToLogin from 'components/ReturnToLogin'
import EmailForm, { EmailFormValues } from 'components/EmailForm'
import { useRegistrationData, useSendInvite } from '../SignUpProvider/sign-up-context'

const useStyles = makeStyles((theme) => ({
  link: {
    color: theme.palette.primary.dark,
    textDecoration: 'underline',
  },
}))

const EmailSignUp = () => {
  const classes = useStyles()
  const [error, setError] = useState('')
  const data = useRegistrationData()
  const sendInvite = useSendInvite()

  const sendMatchInvite = (values: EmailFormValues, props: FormikHelpers<EmailFormValues>) => {
    props.setSubmitting(true)
    api
      .post('/api/student/register/invite', {
        Email: values.email,
        HighSchools: data.highSchool ? [data.highSchool] : [],
        IsPostGradStudent: true,
      })
      .then(() => {
        sendInvite({ Email: values.email })
        props.setSubmitting(false)
      })
      .catch((err) => {
        const errMsg =
          err.status === 409
            ? 'An account with this email already exists. If you don’t remember your password, try resetting it.'
            : 'An unexpected error occurred, please try again later.'
        setError(errMsg)
        props.setSubmitting(false)
      })
  }

  return (
    <Box>
      <Title title="Sign up with your email" />
      <EmailForm
        initialValue={{ email: '' }}
        handleSubmit={sendMatchInvite}
        error={error}
      />
      <Box>
        <TermsAndPrivacy />
        <Typography>
          <Link
            to={`/signup${window.location.search}`}
            className={classes.link}
          >
            Not a Student?
          </Link>
        </Typography>
        <ReturnToLogin />
      </Box>
    </Box>
  )
}

export default EmailSignUp
