import React from 'react'
import { useLocation } from 'react-router-dom'

import { Box, Container, Hidden, Paper, makeStyles } from '@material-ui/core'

import Snackbar from 'components/Snackbar'
import Footer from 'components/Footer'
import DemoBanner from 'components/DemoBanner'
import scoirLogo from 'assets/scoir-logo.svg'
import coalitionLogo from 'assets/coalition-logo.svg'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    minHeight: '100vh',
    background: `linear-gradient(180deg, #7ED2FC 0%, #B0EAE4 100%)`,
    justifyContent: 'space-between',
    flexDirection: 'column',
  },
  container: {
    margin: 'auto',
  },
  main: {
    margin: theme.spacing(3, 0),
    padding: theme.spacing(3),
    textAlign: 'center',
  },
  logoContainer: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: theme.spacing(2),
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
    },
  },
  logo: {
    flexShrink: 0,
    margin: 'auto',
  },
  backButton: {
    display: 'flex',
    alignItems: 'flex-start',
    padding: 0,
  },
}))

const InAppLayout = ({ children }: { children: React.ReactNode }) => {
  const classes = useStyles()
  const { pathname } = useLocation()
  const withCoalition = pathname.includes('/app')

  return (
    <div className={classes.root}>
      <DemoBanner />
      <Container
        className={classes.container}
        fixed
        maxWidth="xs"
      >
        <Paper
          elevation={0}
          className={classes.main}
        >
          <Box className={classes.logoContainer}>
            <img
              className={classes.logo}
              src={scoirLogo}
              alt="Scoir Logo"
            />
            {withCoalition && (
              <>
                <Box
                  aria-hidden="true"
                  m={2}
                >
                  <Hidden xsDown>
                    <span>X</span>
                  </Hidden>
                </Box>
                <img
                  className={classes.logo}
                  src={coalitionLogo}
                  alt="Coalition Logo"
                />
              </>
            )}
          </Box>
          {children}
        </Paper>
      </Container>
      <Snackbar />
      <Footer />
    </div>
  )
}

export default InAppLayout
