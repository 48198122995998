import React from 'react'

import { isEmpty, get } from 'lodash'
import { Box } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { FormikHelpers } from 'formik'

import api from 'common/api'

import LoginWithCleverButton, { VALID_REDIRECT_URIS } from 'components/SSOButtons/LoginWithCleverButton'
import LoginWithClassLinkButton from 'components/SSOButtons/LoginWithClassLinkButton'
import Title from 'components/Title'
import EmailForm, { EmailFormValues } from 'components/EmailForm'
import OrLine from 'components/OrLine'

import {
  useRegistrationData,
  useMatchByEmailFailure,
  useFoundMatch,
  useSendInvite,
} from '../SignUpProvider/sign-up-context'
import CleverSyncError from './components/CleverSyncError'
import { HighSchool } from 'types/@scoir/apiTypes'

const useStyles = makeStyles((theme) => ({
  highSchoolName: {
    color: theme.palette.secondary.main,
  },
}))

export const ERRORS = {
  403: "Can't create an account with that email. Try creating an account with Clever instead.",
  409: 'An account with this email already exists. If you don’t remember your password, try resetting it.',
}

const EmailMatch = () => {
  const classes = useStyles()
  const data = useRegistrationData()
  const [error, setError] = React.useState()
  const [needsFallbackMatch, setNeedsFallbackMatch] = React.useState(false)
  const matchByEmailFailure = useMatchByEmailFailure()
  const foundMatch = useFoundMatch()
  const sendInvite = useSendInvite()

  const inviteStudent = (invitee: { Email: string; HighSchools: HighSchool[] }) => {
    return api.post('/api/student/register/invite', invitee)
  }

  const inviteOffTheStreetStudent = (email: string, formIsSubmitting: (isSubmitting: boolean) => void) => {
    const invitee = {
      Email: email,
      HighSchools: [data.highSchool],
    }
    inviteStudent(invitee)
      .then(() => {
        sendInvite({ Email: email, unmatched: true })
        formIsSubmitting(false)
      })
      .catch((err) => {
        const errorMsg = get(ERRORS, err.status, 'An unexpected error occurred, please try again later.')
        setError(errorMsg)
        formIsSubmitting(false)
      })
  }

  // TODO: TYPE matchedStudent
  const inviteMatchedStudent = (matchedStudent: any, formIsSubmitting: (isSubmitting: boolean) => void) => {
    const match = {
      ...matchedStudent,
      HighSchool: data.highSchool,
    }
    const invitee = {
      ...match,
      HighSchoolStudentId: match.Id,
      HighSchools: [match.HighSchool],
    }
    inviteStudent(invitee)
      .then(() => {
        sendInvite(match)
        formIsSubmitting(false)
      })
      .catch((err) => {
        const errorMsg = get(ERRORS, err.status, 'An unexpected error occurred, please try again later.')
        setError(errorMsg)
        formIsSubmitting(false)
      })
  }

  const handleNoMatch = (email: string, formIsSubmitting: (isSubmitting: boolean) => void) => {
    if (data.highSchool.isOther) {
      inviteOffTheStreetStudent(email, formIsSubmitting)
    } else {
      matchByEmailFailure(email)
    }
  }

  const submitEmail = (values: EmailFormValues, props: FormikHelpers<EmailFormValues>) => {
    props.setSubmitting(true)
    const req: { [key: string]: string } = {
      Email: values.email,
    }
    if (!data.highSchool.isOther) {
      req.HighSchoolId = data.highSchool.HighSchoolId
    }
    api
      .post('/api/student/register/match', req)
      .then((matchedStudent) => {
        if (isEmpty(matchedStudent)) {
          handleNoMatch(values.email, props.setSubmitting)
          return
        }
        const matchHsId = get(matchedStudent, 'HighSchool.HighSchoolId')
        if (isEmpty(matchHsId)) {
          inviteMatchedStudent(matchedStudent, props.setSubmitting)
        } else {
          foundMatch({ email: values.email }, matchedStudent)
          props.setSubmitting(false)
        }
      })
      .catch((err) => {
        const errorMsg = get(ERRORS, err.status)
        if (errorMsg) {
          setError(errorMsg)
          props.setSubmitting(false)
        } else {
          handleNoMatch(values.email, props.setSubmitting)
        }
      })
  }

  const hs = get(data, 'highSchool', {})
  const cleverSynced = hs.SISSyncEnabled && hs.SISSyncPerformed
  const classLinkEnabled = hs.ClassLinkEnabled

  if (needsFallbackMatch) {
    return <CleverSyncError />
  }
  return (
    <Box>
      {data.highSchool.isOther ? (
        <Title title="Let’s try to find your school using your email address 🔍" />
      ) : (
        <Title
          title={
            <>
              Great! We’ll link you to
              <span className={classes.highSchoolName}> {data.highSchool.HighSchoolName} </span>
              🔗
            </>
          }
        />
      )}
      <EmailForm
        initialValue={{ email: '' }}
        fieldText="School Email"
        handleSubmit={submitEmail}
        error={error}
      />
      {cleverSynced && (
        <>
          <OrLine />
          <LoginWithCleverButton
            redirectUri={VALID_REDIRECT_URIS.cleverLink}
            onError={() => setNeedsFallbackMatch(true)}
          />
        </>
      )}
      {classLinkEnabled && (
        <>
          <OrLine />
          <LoginWithClassLinkButton />
        </>
      )}
    </Box>
  )
}

export default EmailMatch
