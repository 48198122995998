import React, { useState, useMemo, useCallback, useEffect } from 'react'

import { TextField, Box, Typography, Button } from '@material-ui/core'
import { validate } from 'email-validator'

import api from 'common/api'

export const Profile = () => {
  useEffect(() => {
    document.title = 'Scoir - Supportal Reauthenticate'
  }, [])

  const [email, setEmail] = useState<string>('')
  const [isSaving, setIsSaving] = useState<boolean>(false)
  const [sentRefresh, setSentRefresh] = useState<boolean>(false)
  const [errorMessage, setErrorMessage] = useState<string>('')

  const isEmailValid = useMemo(() => (email ? validate(email) : true), [email])

  const submit = useCallback(() => {
    setIsSaving(true)
    api
      .post<{ Email: string }, null>('/api/supportal/login', { Email: email })
      .then(() => {
        setSentRefresh(true)
      })
      .catch(() => {
        setErrorMessage('Unable to submit profile update at this time.')
      })
      .finally(() => setIsSaving(false))
  }, [email])

  return (
    <Box>
      {sentRefresh ? (
        <Typography>Success! Please log in with the link in your email.</Typography>
      ) : (
        <>
          <Box mb={2}>
            <Typography>
              If a student requested a recommendation or application documents from you, please enter your email below
              to receive a Log In Link.
            </Typography>
          </Box>
          <form
            onSubmit={(e) => {
              e.stopPropagation()
              e.preventDefault()
              submit()
            }}
          >
            <TextField
              fullWidth
              margin="normal"
              value={email}
              error={!isEmailValid}
              FormHelperTextProps={{ role: 'alert' }}
              helperText={isEmailValid ? '' : 'Must be a valid email'}
              id="email"
              label="Email"
              type="email"
              onChange={(e) => {
                setEmail(e.target.value)
              }}
              variant="outlined"
            />
            {errorMessage && (
              <>
                <Box mt={2}>
                  <Typography
                    align="center"
                    role="alert"
                    aria-live="polite"
                    color="error"
                  >
                    We encountered an unexpected issue. Try again in a few minutes and contact{' '}
                    <a
                      href="https://www.scoir.com/support"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Scoir support
                    </a>{' '}
                    if the problem persists.
                  </Typography>
                </Box>
              </>
            )}
            <Box
              my={2}
              textAlign="center"
            >
              <Button
                disabled={isSaving || !email || !isEmailValid}
                color="primary"
                variant="contained"
                size="large"
                type="submit"
              >
                Send Log In Link
              </Button>
            </Box>
          </form>
        </>
      )}
    </Box>
  )
}

export default Profile
