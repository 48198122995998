import React from 'react'

import { get } from 'lodash'

import { useSignUpStep } from '../SignUpProvider/sign-up-context'
import SelectHighSchool from './SelectHighSchool'
import EmailMatch from './EmailMatch'
import IdDobMatch from './IdDobMatch'
import MatchConfirm from './MatchConfirm'
import CleverRequired from './CleverRequired'
import ClassLinkRequired from './ClassLinkRequired'
import { StudentInvite, UnmatchedStudentInvite } from './InviteSent'
import EmailSignUp from './EmailSignUp'
import MiddleSchoolSignUp from './MiddleSchoolSignUp'

const SIGN_UP_STEPS = {
  SelectHighSchool,
  EmailMatch,
  IdDobMatch,
  MatchConfirm,
  CleverRequired,
  ClassLinkRequired,
  StudentInvite,
  UnmatchedStudentInvite,
  EmailSignUp,
  MiddleSchoolSignUp,
}

const SignUpStep = () => {
  const signUpStep = useSignUpStep()

  const StepComponent = get(SIGN_UP_STEPS, signUpStep, SelectHighSchool)
  return <StepComponent />
}

export default SignUpStep
