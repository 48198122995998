import React, { useState } from 'react'
import { ErrorMessage, Field, Form, Formik, FormikHelpers } from 'formik'
import * as Yup from 'yup'
import { Button, TextField, Typography, makeStyles } from '@material-ui/core'
import { ConfirmResponse } from 'types/@scoir/apiTypes'

import api from 'common/api'
import { clearLocalStorage } from 'common/login-service'
import { setCookies } from 'common/login-service'
import { routeToApp } from 'common/login-service'

import Title from 'components/Title'

const useStyles = makeStyles((theme) => ({
  resetForm: {
    marginTop: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2),
  },
  continueButton: {
    marginTop: theme.spacing(2),
  },
}))

type ResetPasswordFormValues = {
  Password: {
    Pass: string
    PassConfirm: string
  }
}
type ResetPasswordProps = {
  userId?: string
  confirmCode?: string
}

const ResetPassword = ({ userId, confirmCode }: ResetPasswordProps) => {
  const classes = useStyles()
  const [reset, setReset] = useState(false)
  const [response, setResponse] = useState<ConfirmResponse>()
  const [error, setError] = useState(false)

  const handleSubmit = (values: ResetPasswordFormValues, props: FormikHelpers<ResetPasswordFormValues>) => {
    props.setSubmitting(true)
    const body = {
      Id: userId,
      ...values,
      resetCode: confirmCode,
    }
    api
      .post<unknown, ConfirmResponse>('/api/user/password/reset', body)
      .then((res) => {
        setResponse(res)
        setReset(true)
      })
      .catch(() => {
        setError(true)
        props.setSubmitting(false)
      })
  }
  const handleLogin = () => {
    const userType = response?.UserTypes[0]
    clearLocalStorage(userType)
    setCookies(userType, response?.access_token)
    routeToApp(userType)
  }
  const initialValue = {
    Password: {
      Pass: '',
      PassConfirm: '',
    },
  }
  const yupValidation = Yup.object().shape({
    Password: Yup.object().shape({
      Pass: Yup.string()
        .min(8, 'Your password must be at least 8 characters.')
        .required('Your password must be at least 8 characters.'),
      PassConfirm: Yup.string()
        .oneOf([Yup.ref('Pass')], 'Your passwords must match.')
        .required('Your passwords must match.'),
    }),
  })

  if (reset) {
    return (
      <>
        <Title title="Your password has been reset ✅" />
        <Button
          className={classes.continueButton}
          variant="contained"
          color="primary"
          onClick={handleLogin}
        >
          Log In
        </Button>
      </>
    )
  }

  return (
    <>
      <Title title="Set a new password 🔑" />
      <Formik
        initialValues={initialValue}
        validateOnMount
        validationSchema={yupValidation}
        onSubmit={handleSubmit}
      >
        {({ errors, touched, isValid, isSubmitting }) => (
          <Form
            className={classes.resetForm}
            name="resetPassword"
          >
            <Field
              type="password"
              id="pass"
              name="Password.Pass"
              as={TextField}
              required
              fullWidth
              variant="outlined"
              label="Password"
              helperText={
                errors.Password?.Pass && touched.Password?.Pass ? (
                  <ErrorMessage name="Password.Pass" />
                ) : (
                  'Your password must be at least 8 characters.'
                )
              }
              error={errors.Password?.Pass && touched.Password?.Pass}
            />
            <Field
              type="password"
              id="passConfirm"
              name="Password.PassConfirm"
              as={TextField}
              required
              fullWidth
              variant="outlined"
              label="Confirm Password"
              helperText={<ErrorMessage name="Password.PassConfirm" />}
              error={errors.Password?.PassConfirm && touched.Password?.PassConfirm}
            />
            {error && (
              <Typography
                role="alert"
                color="error"
              >
                An unexpected error occurred, please try again later.
              </Typography>
            )}
            <Button
              type="submit"
              color="primary"
              variant="contained"
              disabled={!isValid || isSubmitting}
            >
              Continue
            </Button>
          </Form>
        )}
      </Formik>
    </>
  )
}

export default ResetPassword
