export const OTHER_REASON = 'other'
const unsubscribeReasons = [
  {
    value: 'military',
    label: 'I’m joining the military',
  },
  {
    value: 'idk',
    label: 'I don’t know what Scoir is',
  },
  {
    value: 'afford',
    label: 'I don’t think I can afford college',
  },
  {
    value: 'decided',
    label: 'I already know where I’m going to college',
  },
  {
    value: 'trade',
    label: 'I’m attending a trade school',
  },
  {
    value: 'work',
    label: 'I plan to work full-time',
  },
  {
    value: OTHER_REASON,
    label: 'Other',
  },
]

export const appDeadlineReasons = [
  {
    value: 'notApplying',
    label: `I'm not applying to these colleges anymore`,
  },
  {
    value: 'differentMethod',
    label: `I'm applying to these colleges a different way`,
  },
  {
    value: 'differentDocumentDeliveryMethod',
    label: `My school sends documents another way`,
  },
  {
    value: OTHER_REASON,
    label: 'Other',
  },
]

export default unsubscribeReasons
