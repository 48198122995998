import { Switch, Route, Redirect } from 'react-router-dom'
import qs from '@scoir/query-string'
import { get, toInteger } from 'lodash'

import InAppLayout from './InAppLayout'
import SignInRoute from './SignIn'
import SignUpUserType from './SignUp'
import StudentSignUp from './StudentSignUp'
import CoalitionSignUp from './StudentSignUp/CoalitionSignUp'
import CleverSignUp from './oauth/clever/CleverSignUp'
import StudentRegisterRoute from './register/student/Student'
import CoalitionStudentRegisterRoute from './register/student/Coalition'
import GuidanceOrgRegisterRoute from './register/student/GuidanceOrg'
import EmailDomainStatusRoute from './EmailDomainStatus'
import UnsubscribeInvitesRoute from './UnsubscribeInvites'
import ParentSignUp from './ParentSignUp'
import ParentRegisterRoute from './register/parent'
import RegisterFromInviteRoute from './register/byInvitation'
import ForgotPassword from './ForgotPassword/Forgot'
import ResetPassword from './ForgotPassword/Reset'
import Reauthenticate from './Reauthenticate'
import Oauth2Routes from './oauth2'

const AuthRoutes = () => {
  return (
    <InAppLayout>
      <Switch>
        <Route
          path="/signin"
          component={SignInRoute}
        />
        {/* TODO confirm path w product */}
        <Route
          path="/app/supporter"
          component={Reauthenticate}
        />
        <Route
          exact
          path="/signup"
          render={(props) => {
            const searchParams = qs.parse(props.location.search)
            const hsid = searchParams.hsid
            return hsid ? <Redirect to={`/signup/student?hsid=${hsid}`} /> : <SignUpUserType />
          }}
        />
        <Route
          path="/signup/student"
          render={(routeProps) => (
            <StudentSignUp highSchoolCeebCode={qs.parse(get(routeProps, 'location.search')).hsid} />
          )}
        />
        <Route
          path="/signup/parent"
          component={ParentSignUp}
        />
        <Route
          path={['/app/signup/:scid', '/app/signup']}
          render={(routeProps) => {
            const scid = get(routeProps, 'match.params.scid')
            return <CoalitionSignUp scid={scid && toInteger(scid)} />
          }}
        />
        <Route
          path="/oauth/clever/link"
          component={CleverSignUp}
        />
        <Route
          path="/oauth2"
          component={Oauth2Routes}
        />
        <Route
          path="/register/student/signup/app/:token"
          render={(routeProps) => <CoalitionStudentRegisterRoute token={routeProps.match.params.token} />}
        />
        <Route
          path="/register/student/signup/guidance/:token"
          render={(routeProps) => <GuidanceOrgRegisterRoute token={routeProps.match.params.token} />}
        />
        <Route
          path="/register/student/signup/:token"
          render={(routeProps) => <StudentRegisterRoute token={routeProps.match.params.token} />}
        />
        <Route
          path="/confirm/student/signup/:token"
          render={(routeProps) => (
            <RegisterFromInviteRoute
              token={routeProps.match.params.token}
              userType="student"
            />
          )}
        />
        <Route
          path="/register/parent/signup/:token"
          render={(routeProps) => <ParentRegisterRoute token={routeProps.match.params.token} />}
        />
        <Route
          path="/confirm/parent/:id/:code"
          render={(routeProps) => (
            <RegisterFromInviteRoute
              id={routeProps.match.params.id}
              code={routeProps.match.params.code}
              userType="parent"
            />
          )}
        />
        <Route
          path="/not-interested"
          render={(routeProps) => {
            const queryParams = qs.parse(get(routeProps, 'location.search'))
            return (
              <UnsubscribeInvitesRoute
                {...routeProps}
                inviteToken={get(queryParams, 'token')}
                subToken={get(queryParams, 'sToken')}
                messageTag={get(queryParams, 'tag')}
                cleverRequired={get(queryParams, 'cleverRequired') === 'true'}
              />
            )
          }}
        />
        <Route
          exact
          path="/highschool/:highSchoolId/email-domain/:domainId"
          render={({ match }) => (
            <Redirect
              exact
              to={`/organization/${get(match, 'params.highSchoolId')}/email-domain/${get(match, 'params.domainId')}`}
            />
          )}
        />
        <Route
          path="/organization/:organizationId/email-domain/:domainId"
          render={(routeProps) => (
            <EmailDomainStatusRoute
              organizationId={routeProps.match.params.organizationId}
              domainId={routeProps.match.params.domainId}
              {...routeProps}
            />
          )}
        />
        <Route
          path="/forgot-password"
          component={ForgotPassword}
        />
        <Route
          path="/reset/:userId/:confirmCode"
          render={(routeProps) => (
            <ResetPassword
              userId={routeProps.match.params.userId}
              confirmCode={routeProps.match.params.confirmCode}
            />
          )}
        />
        <Redirect
          from="*"
          to="/signin"
        />
      </Switch>
    </InAppLayout>
  )
}

export default AuthRoutes
