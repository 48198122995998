const SignInErrorConstants = (
  errorCode?:
    | 'inactive'
    | 'clever'
    | 'cleverUnauthorized'
    | 'cleverNoRoles'
    | 'classLink'
    | 'classLinkUnauthorized'
    | 'google'
    | 'userNotFound'
) => {
  const codeToConstantDictionary = {
    inactive:
      'Your account is no longer active. If you feel this is an error, please talk with your Account Administrator.',
    clever: 'An error occurred while trying to log in with your Clever account.',
    cleverUnauthorized: "We aren't able to log you in via Clever. Please try another login method.",
    cleverNoRoles: "Your Scoir Account Administrator has not yet assigned your roles. Please contact them for assistance.",
    classLink: 'An error occurred while trying to log in with your ClassLink account.',
    classLinkUnauthorized: "We aren't able to log you in via ClassLink. Please try another login method.",
    google: 'An error occurred while trying to log in with your Google account.',
    userNotFound:
      'An account with that email was not found. Please choose another way to log in or create a new account.',
  }

  const lookup = errorCode ? codeToConstantDictionary[errorCode] : undefined

  return lookup !== undefined ? lookup : null
}

export default SignInErrorConstants
