import React, { Fragment, useEffect, useState } from 'react'
import PropTypes from 'prop-types'

import CopyToClipboard from '@scoir/components-core/dist/components/CopyToClipboard'
import SurveySecondary from '@scoir/brand-icons/dist/SurveySecondary'
import CheckMarkSecondary from '@scoir/brand-icons/dist/CheckMarkSecondary'

import { Box, Button, CircularProgress, Typography, makeStyles } from '@material-ui/core'
import { addUrlProps, UrlQueryParamTypes } from 'react-url-query'
import { isUndefined } from 'lodash'

import api from 'common/api'

import DomainStateDisplay, { getDescription } from './DomainStateDisplay'
import Title from 'components/Title'

const useStyles = makeStyles((theme) => ({
  copyContainer: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(1),
    marginBottom: theme.spacing(2),
  },
  label: {
    width: theme.spacing(4),
  },
  copy: {
    flex: 1,
  },
  buttonContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2),
  },
}))

export const EmailDomainStatusRoute = ({ organizationId, linkId, domainId }) => {
  const classes = useStyles()
  document.title = 'Scoir - Email Domain Text Records'
  const [isVerifying, setIsVerifying] = useState(false)
  const [domain, setDomain] = useState(undefined)
  const [errorLoading, setErrorLoading] = useState(false)
  const hasDomain = !isUndefined(domain)
  const [errorVerifying, setErrorVerifying] = useState(false)
  useEffect(() => {
    api
      .get(`/api/organizations/${organizationId}/email/domain/${domainId}?linkId=${linkId}`)
      .then((domain) => setDomain(domain))
      .catch((errorResponse) => errorResponse.text())
      .then((errMsg) => setErrorLoading(errMsg))
  }, [organizationId, domainId, linkId])

  const supportLink = 'https://scoir.helpdocs.io/article/1pkk1i9v9p-advanced-email-integration'

  const renderCopy = (value, label) => (
    <Box className={classes.copyContainer}>
      <Typography className={classes.label}>{label}</Typography>
      <div className={classes.copy}>
        <CopyToClipboard
          value={value}
          preCopyIcon={<SurveySecondary />}
          postCopyIcon={<CheckMarkSecondary />}
        />
      </div>
    </Box>
  )

  const renderRouteWrapper = (children) => <div className="email-domain-status-route">{children}</div>

  const verify = () => {
    setIsVerifying(true)
    return api
      .post(`/api/organizations/${organizationId}/email/domain/${domainId}/verify`)
      .then((domain) => {
        setDomain(domain)
        setIsVerifying(false)
      })
      .catch((error) => {
        setIsVerifying(false)
        return error.text()
      })
      .then((errorMessage) => setErrorVerifying(errorMessage))
  }
  return renderRouteWrapper(
    <>
      <Title title="Welcome to Your Text Records Manager" />
      {errorLoading && (
        <Typography
          color="error"
          role="alert"
        >
          {errorLoading}
        </Typography>
      )}
      {hasDomain && (
        <Fragment>
          <Typography id="description">{getDescription(domain.State)}</Typography>
          <DomainStateDisplay
            state={domain.State}
            domainName={domain.Domain}
          />
          {renderCopy(domain.DKIM, 'TXT')}
          {renderCopy(domain.DKIMSelector, 'DKIM')}
          {renderCopy(domain.SPF, 'SPF')}
          {errorVerifying && (
            <Typography
              color="error"
              role="alert"
            >
              {errorVerifying}
            </Typography>
          )}
          <Box className={classes.buttonContainer}>
            <Button
              variant="contained"
              color="primary"
              onClick={verify}
              disabled={isVerifying || domain.State === 'verified_state'}
              endIcon={
                isVerifying && (
                  <CircularProgress
                    size={20}
                    color="textSecondary"
                  />
                )
              }
            >
              Mark DNS Updated
            </Button>
            <div className="help-link-container">
              <a
                href={supportLink}
                target="_blank"
                rel="noopener noreferrer"
              >
                Need help?
              </a>
            </div>
          </Box>
        </Fragment>
      )}
    </>
  )
}

EmailDomainStatusRoute.propTypes = {
  organizationId: PropTypes.string.isRequired,
  domainId: PropTypes.string.isRequired,
  linkId: PropTypes.string,
}

const urlPropsQueryConfig = {
  linkId: { type: UrlQueryParamTypes.string },
}

export default addUrlProps({ urlPropsQueryConfig })(EmailDomainStatusRoute)
