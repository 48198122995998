import { useEffect, useState } from 'react'
import { FormikHelpers } from 'formik'
import moment from 'moment'

import { ErrorBoundary } from '@scoir/error-reporter'
import ComponentLoader from '@scoir/components-core/dist/components/ComponentLoader'

import api from 'common/api'
import { clearLocalStorage, routeToApp, setCookies } from 'common/login-service'
import { AuthToken, ConfirmResponse } from 'types/@scoir/apiTypes'

import RegistrationForm, { RegistrationFormValues } from 'components/RegistrationForm'
import Title from 'components/Title'

const CoalitionStudentRegisterRoute = ({ token }: { token: string }) => {
  const [tokenAuth, setTokenAuth] = useState<AuthToken | undefined>()
  const [error, setError] = useState<string>()
  const [loading, setLoading] = useState(true)
  const [confirmError, setConfirmError] = useState(false)
  const [initialValues, setInitialValues] = useState<RegistrationFormValues | undefined>()

  useEffect(() => {
    api
      .post<unknown, AuthToken>(`/api/student/register/invite/validate/${token}`, {})
      .then((auth) => {
        setTokenAuth(auth)
        setInitialValues({
          firstName: '',
          lastName: '',
          password: {
            pass: '',
            passConfirm: '',
          },
          birthdate: undefined,
          gradYear: new Date().getFullYear() + 1,
          confirmTOS: false,
        })
        setLoading(false)
      })
      .catch((err) => {
        const errMsg =
          err.status === 401
            ? 'This Scoir Invitation is no longer valid.'
            : 'An unexpected error occurred, please try again later.'
        setError(errMsg)
        setLoading(false)
      })
  }, [token])

  const registerCoalitionUser = (values: RegistrationFormValues, props: FormikHelpers<RegistrationFormValues>) => {
    props.setSubmitting(true)
    const sessionId = `${window.localStorage.getItem('sessionId')}`
    const params: { [key: string]: string } = { sessionId }
    if (tokenAuth) params.source = tokenAuth?.Source
    if (tokenAuth?.Scid) {
      params.scid = tokenAuth.Scid.toString()
    }
    const paramString = new URLSearchParams(params).toString()
    api
      .post<unknown, ConfirmResponse>(`/api/student/confirm?${paramString}`, {
        BirthDate: moment(values.birthdate!).format('YYYY-MM-DD'),
        Token: tokenAuth?.Token,
        ConfirmationCode: tokenAuth?.ConfirmationCode,
        FirstName: values.firstName,
        LastName: values.lastName,
        Email: tokenAuth?.Email,
        StudentType: tokenAuth?.StudentType,
        Source: tokenAuth?.Source,
        GraduationYear: values.gradYear,
        Password: {
          Pass: values.password.pass,
          PassConfirm: values.password.passConfirm,
        },
        HighSchoolStudentId: tokenAuth?.MatchedHighSchoolStudent?.HighSchoolStudentId ?? null,
      })
      .then((loginResponse) => {
        if (loginResponse.access_token && loginResponse.UserTypes[0]) {
          const userType = loginResponse.UserTypes[0]
          clearLocalStorage(userType)
          setCookies(userType, loginResponse.access_token)
          const routeToAppArgs = {
            referralScid: params.scid,
            referralSource: params.source,
          }
          routeToApp(userType, routeToAppArgs)
          return
        }
        return Promise.reject()
      })
      .catch(() => {
        setConfirmError(true)
        props.setSubmitting(false)
      })
  }

  if (error) {
    return <Title subtitle={error} />
  }
  return (
    <ComponentLoader loaded={!loading}>
      <ErrorBoundary>
        <Title
          title="Welcome to Scoir ✨"
          subtitle="Tell us a little about yourself to begin building your profile."
        />
        <RegistrationForm
          initialValue={initialValues}
          handleSubmit={registerCoalitionUser}
          confirmError={confirmError}
        />
      </ErrorBoundary>
    </ComponentLoader>
  )
}

export default CoalitionStudentRegisterRoute
