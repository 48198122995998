import React from 'react'
import { Formik, Form, Field, ErrorMessage, FormikHelpers } from 'formik'
import { Button, Checkbox, FormControlLabel, TextField, Typography, makeStyles } from '@material-ui/core'
import ReCAPTCHA from 'react-google-recaptcha'

import TermsAndPrivacy from 'components/TermsAndPrivacy'
import { sub } from 'date-fns'
import DatePicker from 'components/MaterialDatePicker'

import CountryTypeahead from './CountryTypeahead'
import { useCountryMapping } from './CountryMappingProvider/hooks'
import { internationalStudentYupValidation, otsStudentYupValidation, studentYupValidation } from './helper'
import CityStateFields from './CityStateFields'

const useStyles = makeStyles((theme) => ({
  registrationForm: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2),
  },
  selectField: {
    textAlign: 'left',
  },
  centerAlign: {
    display: 'flex',
    justifyContent: 'center',
  },
}))

const getYupValidation = (showLocation: boolean, isUSA: boolean) => {
  if (!showLocation) {
    return studentYupValidation
  } else if (isUSA) {
    return otsStudentYupValidation
  } else {
    return internationalStudentYupValidation
  }
}

export type RegistrationFormValues = {
  firstName: string
  lastName: string
  password: {
    pass: string
    passConfirm: string
  }
  birthdate?: Date
  gradYear?: number // TODO required
  confirmTOS?: boolean
  recaptchaResponse?: string
  country?: string
  city?: string
  stateOrProvince?: string
}

type RegistrationFormProps = {
  initialValue?: RegistrationFormValues
  handleSubmit: (values: RegistrationFormValues, props: FormikHelpers<RegistrationFormValues>) => void
  showLocation?: boolean
  useCaptcha?: boolean
  confirmError?: boolean
}

const RegistrationForm = ({
  initialValue,
  handleSubmit,
  useCaptcha,
  confirmError,
  showLocation = false,
}: RegistrationFormProps) => {
  const [isUSA, setIsUSA] = React.useState<boolean>(true)
  const countryMapping = useCountryMapping()
  const classes = useStyles()
  const recaptchaRef = React.createRef<ReCAPTCHA>()

  if (!initialValue) {
    return null
  }
  return (
    <Formik
      initialValues={initialValue}
      validateOnMount
      validationSchema={getYupValidation(showLocation, isUSA)}
      onSubmit={handleSubmit}
    >
      {({ values, errors, touched, isValid, isSubmitting, setFieldValue, setFieldTouched }) => (
        <Form
          className={classes.registrationForm}
          name="registration"
        >
          <Field
            id="firstName"
            name="firstName"
            as={TextField}
            required
            autoFocus
            fullWidth
            variant="outlined"
            label="First Name"
            helperText={<ErrorMessage name="firstName" />}
            error={errors.firstName && touched.firstName}
          />
          <Field
            id="lastName"
            name="lastName"
            as={TextField}
            required
            fullWidth
            variant="outlined"
            label="Last Name"
            helperText={<ErrorMessage name="lastName" />}
            error={errors.lastName && touched.lastName}
          />
          {showLocation && (
            <>
              <CountryTypeahead
                countryMapping={countryMapping}
                selected={values.country}
                helperText={<ErrorMessage name="country" />}
                error={Boolean(errors.country && touched.country)}
                onChange={(val: string) => {
                  setFieldValue('country', val)
                  setIsUSA(val === 'USA')

                  // Reset city and state when country changes
                  setFieldValue('city', initialValue.city)
                  setFieldValue('stateOrProvince', initialValue.stateOrProvince)
                }}
                onBlur={() => setFieldTouched('country', true)}
                required
                isMulti={false}
                withMargin={false}
              />
              <CityStateFields
                country={values.country || initialValue.country}
                setFieldValue={setFieldValue}
                setFieldTouched={setFieldTouched}
                errors={errors}
                touched={touched}
                values={values}
              />
            </>
          )}
          <Field
            type="number"
            id="gradYear"
            name="gradYear"
            as={TextField}
            required
            fullWidth
            variant="outlined"
            label="High School Graduation Year"
            helperText={
              errors.gradYear && touched.gradYear ? <ErrorMessage name="gradYear" /> : 'This can be expected or actual.'
            }
            error={errors.gradYear && touched.gradYear}
          />
          <Field name="birthdate">
            {({ field }: any) => (
              <DatePicker
                pickerIdSuffix="birthdate"
                date={field.value}
                onChange={(updatedDate: any) => {
                  setFieldValue('birthdate', updatedDate, true)
                }}
                onBlur={() => {
                  setFieldTouched('birthdate', true, true)
                }}
                label="Date of Birth"
                id="birthdate"
                format="MM/dd/yyyy"
                placeholder="mm/dd/yyyy"
                maxDate={sub(new Date(), { years: 13 })}
                helperText={
                  errors.birthdate && touched.birthdate ? (
                    <ErrorMessage name="birthdate" />
                  ) : (
                    'You must be at least 13 years of age or older.'
                  )
                }
                error={Boolean(errors.birthdate) && touched.birthdate}
              />
            )}
          </Field>
          <Field
            type="password"
            id="pass"
            name="password.pass"
            as={TextField}
            required
            fullWidth
            variant="outlined"
            label="Password"
            helperText={
              errors.password?.pass && touched.password?.pass ? (
                <ErrorMessage name="password.pass" />
              ) : (
                'Your password must be at least 8 characters.'
              )
            }
            error={errors.password?.pass && touched.password?.pass}
          />
          <Field
            type="password"
            id="passConfirm"
            name="password.passConfirm"
            as={TextField}
            required
            fullWidth
            variant="outlined"
            label="Confirm Password"
            helperText={<ErrorMessage name="password.passConfirm" />}
            error={errors.password?.passConfirm && touched.password?.passConfirm}
          />
          <FormControlLabel
            className={classes.centerAlign}
            label="I certify that I am 13 years of age or older."
            control={
              <Field
                id="confirmTOS"
                name="confirmTOS"
                as={Checkbox}
                disableRipple={false}
                color="primary"
                checked={values.confirmTOS}
                onChange={() => setFieldValue('confirmTOS', !values.confirmTOS, true)}
                onKeyPress={(event: any) => {
                  if (event.key === 'Enter') {
                    setFieldValue('confirmTOS', !values.confirmTOS, true)
                  }
                }}
              />
            }
          />
          {useCaptcha && (
            <ReCAPTCHA
              className={classes.centerAlign}
              ref={recaptchaRef}
              sitekey={window.RECAPTCHA_SITE_KEY}
              onChange={() => {
                setFieldValue('recaptchaResponse', recaptchaRef.current?.getValue(), true)
              }}
            />
          )}
          <TermsAndPrivacy mb={0} />
          {confirmError && (
            <Typography
              role="alert"
              color="error"
            >
              An error occurred while signing up, please try again later.
            </Typography>
          )}
          <Button
            type="submit"
            color="primary"
            variant="contained"
            disabled={!isValid || isSubmitting || (useCaptcha && values.recaptchaResponse === '')}
          >
            Log In
          </Button>
        </Form>
      )}
    </Formik>
  )
}

export default RegistrationForm
