import React from 'react'

import { BrowserRouter } from 'react-router-dom'
import { Provider } from 'react-redux'
import { ThemeProvider } from '@material-ui/core/styles'
import { ErrorBoundary } from '@scoir/error-reporter'

import ErrorReporter from 'components/ErrorReporter'
import QueryParamsProvider from 'components/QueryParamsProvider'
import AuthRoutes from 'routes/AuthRoutes'
import store from './store'
import theme from './theme'
import './bootstrap.scss'
import './App.scss'

const App = () => {
  React.useEffect(() => {
    const preloader = document.getElementById('preloader')
    if (preloader) {
      preloader.remove()
    }
  }, [])
  return (
    <ErrorReporter>
      <ErrorBoundary>
        <BrowserRouter>
          <QueryParamsProvider>
            <Provider store={store}>
              <ThemeProvider theme={theme}>
                <AuthRoutes />
              </ThemeProvider>
            </Provider>
          </QueryParamsProvider>
        </BrowserRouter>
      </ErrorBoundary>
    </ErrorReporter>
  )
}

export default App
