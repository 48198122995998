import React, { useEffect, useState } from 'react'

import { FormikHelpers } from 'formik'
import qs from '@scoir/query-string'
import ComponentLoader from '@scoir/components-core/dist/components/ComponentLoader'

import { useClickTrackingQueryParams, useClearClickTrackingQueryParams } from 'components/QueryParamsProvider'
import { getTrackEventsSessionId } from 'common/get-track-events-session-id'
import { clearLocalStorage, setCookies, routeToApp } from 'common/login-service'
import api from 'common/api'
import Title from 'components/Title'
import ParentRegistrationForm, { ParentRegistrationFormValues } from './registrationForm'
import { ConfirmResponse } from 'types/@scoir/apiTypes'
import CountryMappingProvider from 'components/RegistrationForm/CountryMappingProvider'

type TokenAuth = {
  email: string
  confirmationCode: string
  token: string
}

const ParentRegisterRoute = ({ token }: { token: string }) => {
  const [tokenAuth, setTokenAuth] = useState<TokenAuth | undefined>(undefined)
  const [error, setError] = useState<string | undefined>(undefined)
  const [loading, setLoading] = useState(true)
  const [initialValues, setInitialValues] = useState<ParentRegistrationFormValues | undefined>(undefined)
  const [confirmError, setConfirmError] = useState(false)

  useEffect(() => {
    api
      .post<unknown, TokenAuth>(`/api/parent/register/validate/${token}`, {})
      .then((auth) => {
        setTokenAuth(auth)
        setInitialValues({
          firstName: '',
          lastName: '',
          password: {
            pass: '',
            passConfirm: '',
          },
          recaptchaResponse: '',
          country: 'USA',
          city: '',
          state: '',
        })
        setLoading(false)
      })
      .catch((err) => {
        const errMsg =
          err.status === 401
            ? 'This Scoir Invitation is no longer valid.'
            : 'An unexpected error occurred, please try again later.'
        setError(errMsg)
        setLoading(false)
      })
  }, [token])

  const clickTrackingQueryParams = useClickTrackingQueryParams()
  const onClearClickTrackingQueryParams = useClearClickTrackingQueryParams()
  const registerParentUser = (
    values: ParentRegistrationFormValues,
    props: FormikHelpers<ParentRegistrationFormValues>
  ) => {
    props.setSubmitting(true)
    const params = qs.stringify({
      ...clickTrackingQueryParams,
      sessionId: getTrackEventsSessionId(),
    })
    api
      .post<unknown, ConfirmResponse>(`/api/parent/register/confirm?${params}`, {
        ...values,
        email: tokenAuth?.email,
        token: tokenAuth?.token,
        confirmationCode: tokenAuth?.confirmationCode,
      })
      .then((res) => {
        if (res.access_token && res.UserTypes[0]) {
          const userType = res.UserTypes[0]
          clearLocalStorage(userType)
          setCookies(userType, res.access_token)
          routeToApp(userType)
        } else {
          setConfirmError(true)
          props.setSubmitting(false)
        }
        onClearClickTrackingQueryParams()
      })
      .catch((err) => {
        const errMsg =
          err.status === 401
            ? 'This Scoir Invitation is no longer valid.'
            : 'An unexpected error occurred, please try again later.'
        setError(errMsg)
      })
  }

  if (error) {
    return <Title subtitle={error} />
  }
  return (
    <CountryMappingProvider>
      <ComponentLoader loaded={!loading}>
        <Title
          title="Welcome to Scoir ✨"
          subtitle={`Please activate your account${tokenAuth?.email ? ` for ${tokenAuth.email}` : ''}.`}
        />
        <ParentRegistrationForm
          initialValue={initialValues}
          handleSubmit={registerParentUser}
          confirmError={confirmError}
        />
      </ComponentLoader>
    </CountryMappingProvider>
  )
}

export default ParentRegisterRoute
